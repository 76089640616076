/**
 * Custom hook for managing retirement-related data operations.
 * Provides functionality to load and save retirement information,
 * including assets (home value, savings, investments) and liabilities
 * (mortgages, loans, credit card debt).
 */

import { useContext, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { getMyRetirement, addOrUpdateMyRetirement } from '../apiServices/pfscoreForms/retirement';

import { useNavigate } from "react-router-dom";

export const useRetirement= () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    const loadRetirement = async () => {
        setIsLoading(true);
        setError(null);
        const response = await getMyRetirement()
        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response && response.http_status!==200) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("loadRetirement Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('Retirement', response.getMyRetirement.data);
            setIsLoading(false);
            console.log("Feteched Initial Data for Retirement!!!");
        }
    }

    const saveRetirement = async (updateData) => {
        setIsLoading(true);
        setError(null);
        // marketValuePrimaryHome, marketValueCarsMajorItems, marketValuePersonalValuables, anyOtherAssetsDescription, anyOtherAssetsAmount, cashSavings, currentValueRetirementAccounts, valueAllBrokerageAccounts, mortgageBalancePrimaryHome, loanBalanceCarsOtherMajorItems, creditCardDebtLoanBalance, anyOtherLoansDescription, anyOtherLoansAmount
        const response = await addOrUpdateMyRetirement(...updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response && response.http_status!==200) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("saveRetirement Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('Retirement', response.addOrUpdateMyRetirement.data);
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved Retirement details!!!");
        }
        return isSuccess
    }

    return {loadRetirement, saveRetirement, isLoading, error}
}