/**
 * Custom hook for managing major purchases data in the application.
 * Provides functionality to load, add, update, and remove major purchase records.
 * Handles API interactions, loading states, and local storage management for major purchases.
 */

// useMajorPurchases.js
import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { getMyMajorPurchases, addMyMajorPurchases, updateMyMajorPurchases, removeMyMajorPurchases } from '../apiServices/pfscoreForms/majorPurchases';
import { useNavigate } from "react-router-dom";

export const useMajorPurchases= () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    const loadMajorPurchases = async () => {
        setIsLoading(true);
        setError(null);
        const response = await getMyMajorPurchases()
        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("loadMajorPurchases Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('MajorPurchases', response.getMyMajorPurchases.data);
            setIsLoading(false);
            console.log("Feteched Initial Data for MajorPurchases!!!");
        }
    }

    const addMajorPurchases = async (updateData) => {
        setIsLoading(true);
        setError(null);
        const response = await addMyMajorPurchases(...updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if (response.addMyMajorPurchase.http_status!==200) {
            isSuccess = false;
            setError(response.addMyMajorPurchase.message);
        } else {
            isSuccess = true;
            setError(response.addMyMajorPurchase.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.addMyMajorPurchase.message);
            console.log("saveMajorPurchases Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('MajorPurchases', JSON.stringify(response.addMyMajorPurchase.data));
            // populate form with initial values
            setIsLoading(false);
            console.log("saved MajorPurchases details!!!");
            return response.addMyMajorPurchase.data;
        }
        return isSuccess
    }

    const updateMajorPurchases = async (updateData) => {
        setIsLoading(true);
        setError(null);
        const response = await updateMyMajorPurchases(...updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if (response.updateMyMajorPurchase.http_status!==200) {
            isSuccess = false;
            setError(response.updateMyMajorPurchase.message);
        } else {
            isSuccess = true;
            setError(response.updateMyMajorPurchase.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.updateMyMajorPurchase.message);
            console.log("saveMajorPurchases Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('MajorPurchases', response.updateMyMajorPurchase.data);
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved MajorPurchases details!!!");
        }
        return isSuccess
    }

    const removeMajorPurchases = async (id) => {
        setIsLoading(true);
        setError(null);
        const response = await removeMyMajorPurchases(id);        
        console.log("response is:", response);
        let isSuccess = true;
        if (response.removeMyMajorPurchase.http_status!==200) {
            isSuccess = false;
            setError(response.removeMyMajorPurchase.message);
        } else {
            isSuccess = true;
            setError(response.removeMyMajorPurchase.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.removeMyMajorPurchase.message);
            console.log("saveMajorPurchases Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('MajorPurchases', response.removeMyMajorPurchase.data);
            // populate form with initial values
            setIsLoading(false);
            console.log("saved MajorPurchases details!!!");
        }
        return isSuccess
    }

    return {loadMajorPurchases, addMajorPurchases, updateMajorPurchases, removeMajorPurchases, isLoading, error}
}