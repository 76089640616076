
/**
 * CustomDropdown Component
 * 
 * A reusable dropdown component that displays a list of options and allows the user to select one.
 * 
 * @param {Object} props - Component props
 * @param {string} props.defaultValue - The default selected option
 * @param {Array} props.options - The list of options to display
 * @param {Function} props.onSelect - Callback function to handle option selection
 * 
 * @returns {React.ReactElement} - The rendered dropdown component
 */
import React from 'react';
import { Button, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function CustomDropdown({ defaultValue, options, onSelect }) {
  const [selectedOption, setSelectedOption] = React.useState(defaultValue);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    handleClose();
  };

  return (
    <>
      <Button
        id="custom-dropdown-button"
        variant="outlined"
        endIcon={<ArrowDropDownIcon />}
        onClick={handleClick}
        sx={{
          borderRadius: '6px',
          backgroundColor: '#C0FFE4',
          color: "#005E32",
          justifyContent: 'space-between',
          padding: '8px 16px',
          border: 'none',
          '&:hover': {
            backgroundColor: '#C0FFE4',
            color: "#005E32",
            border: 'none',
          }
        }}
      >
        {selectedOption}
      </Button>
      <Menu
        id="custom-dropdown-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'custom-dropdown-button',
        }}
        PaperProps={{
          sx: {
            backgroundColor: '#E2FFF0',
            borderRadius: '18px',
            mt: 1,
            '& .MuiList-root': {
              py: 1,
            },
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
          }
        }}
      >
        {options.map((option) => (
          <MenuItem 
            key={option}
            onClick={() => handleOptionSelect(option)}
            selected={selectedOption === option}
            sx={{
              color: '#00C86A',
              fontSize: '14px',
              py: 1,
              px: 3,
              backgroundColor: selectedOption === option ? 'rgba(0, 200, 106, 0.1)' : 'transparent',
              '&:hover': {
                backgroundColor: 'rgba(0, 200, 106, 0.1)',
              },
              minWidth: '180px',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default CustomDropdown;