import React from "react";
import { Helmet } from "react-helmet";
import FooterImg from "../../../components/footerimg/FooterImg";
import HeroSection from "../../../components/hero/HeroSection";
import heroImage from "../../../assets/images/about-us-hero.png";
import Team from "../../../components/Team/Team";

import BackLink from '../../../components/backLink/BackLink'
import Container from "@mui/material/Container"

const Home = () => {

  return (
    <>
      <Helmet>
        <title>About PFScores | Your Partner in Financial Success</title>
        <meta 
          name="description" 
          content="PFScores offers a free app for personalized financial assessments and insights. Get your score, compare with others, and start your path to financial freedom!"
        />
      </Helmet>

      <section className="aboutUs">
        <Container maxWidth="lg" >
        <BackLink sx={{marginBottom: 0}}/>
        </Container>

        <HeroSection title="About Us" heroImage={heroImage} showAboutLink={false} description={<>PFScores offers adults a free, easy-to-use, comprehensive on-line benchmarking app that allows individuals and their families to measure their overall financial health. PFScores takes only 10 to 15 minutes to complete. <br/><br/> After entering your data, users receive a score, a ranking against other users, and a report explaining how their score was calculated.</>} />
        <Team/>
        <FooterImg />
      </section>
    </>
  );
};

export default Home;
