import { Container, Box, Button, Menu, MenuItem } from "@mui/material";
import { BackDropEffect } from "../backDropEffect/BackDropEffect";
import BackLink from "../backLink/BackLink";
import { Typography } from "@mui/material";
import { Line } from 'react-chartjs-2';
import DownloadIcon from '@mui/icons-material/Download';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
} from 'chart.js';
import { generatePdf } from "../../utils/pdfGenerator";
import { getLatestScore } from "../../apiServices/pfscoreForms/completeScore";
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/**
 * ScoreGraph Component
 * 
 * A visualization component that displays the user's financial score history over time.
 * Features include:
 * - Line graph showing score progression
 * - Time period filtering (week, month, year, etc.)
 * - PDF report generation
 * - Interactive tooltips showing exact scores
 * - Responsive design for mobile and desktop views
 */

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

function ScoreGraph({ scoreData, selectedOption, onTimeFilterChange }) {
  // Initialize data with empty values instead of null
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Score',
        data: [],
        fill: true,
        borderColor: '#2ed1a1',
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 400);
          gradient.addColorStop(0, '#65FFB57D');
          gradient.addColorStop(1, '#5FFFB200');
          return gradient;
        },
        tension: 0.4,
        pointRadius: 4,
        pointBackgroundColor: '#2ed1a1',
        pointBorderColor: '#2ed1a1',
        pointHoverRadius: 6,
        borderWidth: 2,
      },
    ],
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    onTimeFilterChange(option);
    handleClose();
  };

  // Update data when scoreData changes
  useEffect(() => {
    if (scoreData && scoreData.length > 0) {
      setData({
        labels: scoreData.map(item => item.formattedDate),
        datasets: [
          {
            ...data.datasets[0], // Keep existing dataset properties
            data: scoreData.map(item => item.score),
          },
        ],
      });
    }
  }, [scoreData]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        max: 750,
        ticks: {
          stepSize: 150,
        },
        grid: {
          drawBorder: false,
        },
        border: {
          display: false,
        }
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        border: {
          display: false,
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: 'white',
        titleColor: 'black',
        bodyColor: 'black',
        borderColor: '#e0e0e0',
        borderWidth: 1,
        padding: 10,
        displayColors: false,
        callbacks: {
          title: (context) => {
            return context[0].label;
          },
          label: (context) => {
            return `Score: ${context.raw}`;
          },
        },
      },
    },
  };

  const handleDownloadReport = async () => {
    let scoreData;
    try {
      const response = await getLatestScore();
      if (response.getLatestScore.code === "SUCCESS") {
        scoreData = response.getLatestScore.data;
      } else {
        console.log("error in fetching:", response.getLatestScore.message);
        return;
      }
    } catch (err) {
      console.log("Failed to fetch score data");
      console.error(err);
      return;
    }

    if (!scoreData) return;

    const userData = {
      personalDetails: {
        college: scoreData.personalDetails.plan_to_save_for_college,
        ownHome: scoreData.personalDetails.own_home,
        addProperties: scoreData.personalDetails.own_additional_properties,
        business: scoreData.personalDetails.own_business,
        majorPurchases: scoreData.personalDetails.plan_for_major_purchase,
        houseHold: scoreData.personalDetails.annual_household_income,
        investment: scoreData.personalDetails.value_of_investments,
        knowCreditScore: scoreData.personalDetails.know_credit_score,
        haveCollegeSavingPlan: scoreData.personalDetails.have_college_saving_plan,
        knowValueOfHome: scoreData.personalDetails.know_value_of_home,
      },
      netWorth: {
        // Lifestyle Assets
        laPrimaryHome: scoreData.netWorth.market_value_primary_home,
        laCarAndOthers: scoreData.netWorth.market_value_cars_major_items,
        laPersonalValuables: scoreData.netWorth.market_value_personal_valuables,
        laAnyOther: scoreData.netWorth.any_other_assets_amount,

        // Savings and Investments
        siTotal: scoreData.netWorth.total_savings,
        siCash: scoreData.netWorth.cash_savings,
        siRetirementAc: scoreData.netWorth.current_value_retirement_accounts,
        siBrokerageAc: scoreData.netWorth.value_all_brokerage_accounts,

        // Liabilities
        isMortgagePrimaryHome: scoreData.netWorth.is_mortgage_primary_home,
        mortgageInterestRate: scoreData.netWorth.mortgage_interest_rate,
        mortgageYearsLeft: scoreData.netWorth.mortgage_years_left,
        mortgageType: scoreData.netWorth.mortgage_type,
        mortgageYearsTotal: scoreData.netWorth.mortgage_years_total,
        lMortgage: scoreData.netWorth.mortgage_balance_primary_home,
        lCarsOthers: scoreData.netWorth.loan_balance_cars_other_major_items,
        lCcDebtLoan: scoreData.netWorth.credit_card_debt_loan_balance,
        isOtherLoan: scoreData.netWorth.is_other_loan,
        lAnyOther: scoreData.netWorth.any_other_loans_amount,
      },
      cashManagement: {
        // Income
        iSalary: scoreData.cashManagement.salary_bonus_pretax_amount,
        iSalaryPeriod: scoreData.cashManagement.salary_bonus_pretax_period,
        iRental: scoreData.cashManagement.rental_income_amount,
        iRentalPeriod: scoreData.cashManagement.rental_income_period_period,
        iAnyOther: scoreData.cashManagement.any_other_income_amount,
        iAnyOtherPeriod: scoreData.cashManagement.any_other_income_period,
        iAnyOtherDesc: scoreData.cashManagement.any_other_income_description,

        // Property Related Expenses
        prHomeMortgage: scoreData.cashManagement.home_mortgage_payment_amount,
        prHomeMortgagePeriod: scoreData.cashManagement.home_mortgage_payment_period,
        prPropertyTax: scoreData.cashManagement.property_taxes_annual,
        prOther: scoreData.cashManagement.other_expenses_amount,

        // Living Expenses
        leLife: scoreData.cashManagement.life_expenses_amount,
        leLifePeriod: scoreData.cashManagement.life_expenses_period,
        leHealthInsurance: scoreData.cashManagement.health_insurance_payment_amount,
        leHealthInsurancePeriod: scoreData.cashManagement.health_insurance_payment_period,
        leTravel: scoreData.cashManagement.travel_entertainment_amount,
        leTravelPeriod: scoreData.cashManagement.travel_entertainment_period,

        // Other Expenses
        oeEstimatedTaxesAnnual: scoreData.cashManagement.estimated_taxes_annual,
        oeMonthlyOther: scoreData.cashManagement.any_other_monthly_payments_amount,
        oeMonthlyOtherDesc: scoreData.cashManagement.any_other_monthly_payments_desc,
      },
      retirement: {
        yearsToRetire: scoreData.retirement.number_of_years_left_to_retire,
        retirementDuration: scoreData.retirement.expected_duration_of_retirement,
        leHousing: scoreData.retirement.housing_expenses_amount,
        leHousingPeriod: scoreData.retirement.housing_expenses_period,
        healthcareExpenses: scoreData.retirement.healthcare_expenses,
        lifeExpenses: scoreData.retirement.life_expenses,
        teExpenses: scoreData.retirement.te_expenses,
        otherExpenses: scoreData.retirement.other_expenses,
        elMortgage: scoreData.retirement.expected_mortgage_at_retirement,
        elLoans: scoreData.retirement.other_expected_loans_at_retirement,
        elLegacy: scoreData.retirement.legacy_you_want_to_leave,
        rsRetirementAc: scoreData.retirement.all_retirement_accounts_value,
        rsContributionByEmployer: scoreData.retirement.average_monthly_contribution_by_employer,
        rsContributionByYou: scoreData.retirement.monthly_contribution,
        isspPension: scoreData.retirement.estimated_monthly_pension_benefit,
        isspSocSec: scoreData.retirement.estimated_monthly_soc_sec_benefit,
        emfInflationRate: scoreData.retirement.expected_avg_inflation_rate,
        emfAnnualReturn: scoreData.retirement.expected_avg_annual_return_retirement_balance,
      },
      education: {
        oneYearCollegeCost: scoreData.education.one_year_college_cost,
        avgAnnualTuitionInflation: scoreData.education.avg_annual_tuition_inflation,
        children: scoreData.education.children.map((child) => ({
          nameOfChild: child.name_of_child,
          ageOfChild: child.age_of_child,
          percentOfCostForChild: child.percent_of_cost_for_child,
          amountSavedSoFar: child.amount_saved_so_far,
          currentMonthlySavings: child.current_monthly_savings_for_child,
          expectedAnnualReturn: child.expected_annual_return_on_savings,
          totalMonthlyContributionRequired: child.total_monthly_contribution_required,
        })),
      },
      majorPurchases: scoreData.majorPurchases.map((purchase) => ({
        yearsToPurchase: purchase.num_years_left_for_purchase,
        amountNeeded: purchase.amount_needed_as_full_down_payment,
        amountSaved: purchase.amount_saved_so_far_for_purchase,
        monthlySaving: purchase.current_monthly_savings_for_purchase,
        annualReturn: purchase.exp_avg_annual_return_on_savings,
        nameOfPurchase: purchase.name_of_purchase,
      })),
      dependentProtection: {
        // Insurance and Protection
        onetimeExpenses: scoreData.dependentProtection.other_one_time_expenses,
        yearsToSupport: scoreData.dependentProtection.exp_num_years_support_dependents,
        avgAnnualReturn: scoreData.dependentProtection.exp_avg_annual_long_term_investment,
        avgInflation: scoreData.dependentProtection.exp_annual_inflation_rate,
        insuranceSelfType: scoreData.dependentProtection.self_purchased_life_insurance_type,
        insuranceSelf: scoreData.dependentProtection.self_purchased_life_insurance_amount,
        insuranceEmployer: scoreData.dependentProtection.employer_provided_life_insurance_amount,

        // Insurance Coverage
        haveHomeownersInsurance: scoreData.dependentProtection.have_homeowner_insurance,
        haveHealthInsurance: scoreData.dependentProtection.have_health_insurance,
        haveLtDisabilityInsurance: scoreData.dependentProtection.have_long_term_disability_ins,
        haveStDisabilityInsurance: scoreData.dependentProtection.have_short_term_disability_ins,
        haveUmbrellaPolicy: scoreData.dependentProtection.have_umbrella_policy,

        // Legal Documents
        havePowerOfAttorney: scoreData.dependentProtection.have_power_of_attorney,
        haveRevocableTrust: scoreData.dependentProtection.have_revocable_trust,
        haveLtCareInsurance: scoreData.dependentProtection.have_long_term_care_insurance,
        haveWill: scoreData.dependentProtection.have_will,
        haveLivingWill: scoreData.dependentProtection.have_living_will,
      },
    };

    const scores = {
      percentage: Number(((scoreData.score / 750) * 100).toFixed(2)),
      overAllScore: scoreData.score,
      debtScore: scoreData.debtScore,
      riskScore: scoreData.riskScore,
      savingsScore: scoreData.savingsScore,
    };

    await generatePdf(userData, scores);
  };

  return (
    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />

      <Typography sx={{ fontSize: "34px", fontWeight: 600, marginBottom: "50px" }}>
        Score Change Graph
      </Typography>
      
      {/* Header with buttons */}
      <Box sx={{ 
        display: 'flex', 
        justifyContent: {md:'space-between', xs:'end'}, 
        alignItems: 'center',
        mb: 4 
      }}>
        <Box>
          <Button
            id="custom-dropdown-button"
            variant="outlined"
            endIcon={<ArrowDropDownIcon />}
            onClick={handleClick}
            sx={{
              backgroundColor: '#E2FFF0',
              borderColor: '#00C86A',
              borderRadius: '18px',
              borderWidth: '1px',
              color: "#00C86A",
              minWidth: '180px',
              justifyContent: 'space-between',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#E2FFF0',
                borderColor: '#00C86A',
                color: "#00C86A",
              }
            }}
          >
            {selectedOption}
          </Button>
          <Menu
            id="custom-dropdown-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'custom-dropdown-button',
            }}
            PaperProps={{
              sx: {
                backgroundColor: '#E2FFF0',
                borderRadius: '18px',
                mt: 1,
                '& .MuiList-root': {
                  py: 1,
                },
                boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
              }
            }}
          >
            {[
              'All',
              'Last Week',
              'Last Month',
              'Last 3 Months',
              'Last 6 Months',
              'Last 1 Year',
              'Last 2 Years'
            ].map((option) => (
              <MenuItem 
                key={option}
                onClick={() => handleOptionSelect(option)}
                selected={selectedOption === option}
                sx={{
                  color: '#00C86A',
                  fontSize: '14px',
                  py: 1,
                  px: 3,
                  backgroundColor: selectedOption === option ? 'rgba(0, 200, 106, 0.1)' : 'transparent',
                  '&:hover': {
                    backgroundColor: 'rgba(0, 200, 106, 0.1)',
                  },
                  minWidth: '180px',
                }}
              >
                {option}
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadReport}
          sx={{
            display: {xs: 'none', md: 'flex'},
            backgroundColor: '#C7FFE2',
            color:"#004A27",
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#C7FFE2',
              color:"#004A27",
            }
          }}
        >
          Generate PDF Report
        </Button>
      </Box>

      {/* Chart Section */}
      <Box sx={{ 
        width: '100%', 
        height: 400, 
        backgroundColor: '#f8fffc',
        borderRadius: '10px',
        p: 2
      }}>
        {scoreData && scoreData.length > 0 ? (
          <Line data={data} options={options} />
        ) : (
          <Box sx={{ 
            height: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
          }}>
            <Typography variant="body1" color="textSecondary">
              No score data available for this time period
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{display: {md:'none', xs:'flex'}, justifyContent: 'center', my: 2}}>
      <Button
          variant="contained"
          startIcon={<DownloadIcon />}
          onClick={handleDownloadReport}
          sx={{
            display: 'flex',
            backgroundColor: '#C7FFE2',
            color:"#004A27",
            borderRadius: '8px',
            '&:hover': {
              backgroundColor: '#C7FFE2',
              color:"#004A27",
            }
          }}
        >
          Generate PDF Report
        </Button>
      </Box>
      
    </Container>
  );
}

// Update PropTypes
ScoreGraph.propTypes = {
  scoreData: PropTypes.arrayOf(PropTypes.shape({
    score: PropTypes.number.isRequired,
    formattedDate: PropTypes.string.isRequired,
  })),
  selectedOption: PropTypes.string.isRequired,
  onTimeFilterChange: PropTypes.func.isRequired,
};

export default ScoreGraph;
