import React, { useEffect } from "react";
import "../../authPages/signup/SignUp.css";
import "../../../assets/css/style.css";
import { Container, Box, Checkbox, Button, Alert } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
// import TextField from '@material-ui/core/TextField';
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { useNavigate } from "react-router-dom";
import { useSignup } from '../../../hooks/useSignup';
import { AuthConstants } from '../../../constants/AuthConstants';
import { getUserDetails } from "../../../apiServices";

const Account = () => {
  const { updateProfile} = useSignup();
  const navigate = useNavigate();
  const BirthYearOptions = AuthConstants.BirthYearOptions.getBirthYearOptions(
    16,
    110
  );
  const MaritalStatusOptions = AuthConstants.MaritalStatusOptions;
  const GenderOptions = AuthConstants.GenderOptions;
  const EducationLevelOptions = AuthConstants.EducationLevelOptions;
  
  const datafromreducer = useSelector((state) => state.UserDetailsReducer);
  const funcitonsformreducer = useDispatch();


  
  
  const getCurrentVal = (variable) => {
    const result = (() => {
      switch (variable) {
        case "FIRST_NAME":
          return datafromreducer.first_name;
        case "LAST_NAME":
          return datafromreducer.last_name;
        case "PHONE":
          return datafromreducer.phone;
        case "ZIP":
          return datafromreducer.zip;
        case "BIRTH_YEAR":
          return datafromreducer.birth_year;
        case "GENDER":
          return datafromreducer.gender;
        case "MARITAL_STATUS":
          return datafromreducer.marital_status;
        case "EDUCATION_LEVEL":
          return datafromreducer.education_level;
        default:
          return '';
      }
    })();
    console.log(`getCurrentVal(${variable}):`, result);
    return result;
  };
  
  const inputOnchange = (value, type) => {
    funcitonsformreducer({
      type: type,
      payload: value,
    });
  };
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getUserDetails();
        const data = response.currentUser;
        console.log("fetched data is :", data)
        let updateData = {
          first_name: "",
          last_name: "",
          phone: "",
          zip: "",
          birth_year: "0",
          gender: "No Selected",
          marital_status: "No Selected",
          education_level: "No Selected",
        }
        if (data) {
          updateData = {
            first_name: data.first_name,
            last_name: data.last_name,
            phone: data.phone,
            zip: data.zip,
            birth_year: data.birth_year,
            gender: data.gender,
            marital_status: data.marital_status,
            education_level: data.education_level,
          }
          console.log("data is present ")
        } else {
          console.log("data is not present ")
        }
        funcitonsformreducer({
          type: "SET_ALL_USER_DETAILS",
          payload: updateData,
        });
      } catch (error) {
        console.log("Initial Load Error:", error);
      }
    }
    fetchData()
  }, [funcitonsformreducer])
  
  /**
   * Handles the submission of the account update form.
   * Collects all user details from the form and sends them to the server
   * to update the user's profile information. On successful update,
   * redirects to the activation notification page.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      first_name: getCurrentVal("FIRST_NAME"),
      last_name: getCurrentVal("LAST_NAME"),
      phone: getCurrentVal("PHONE"),
      zip: getCurrentVal("ZIP"),
      birth_year: getCurrentVal("BIRTH_YEAR"),
      gender: getCurrentVal("GENDER"),
      marital_status: getCurrentVal("MARITAL_STATUS"),
      education_level: getCurrentVal("EDUCATION_LEVEL"),
    };
    alert(JSON.stringify(formData, null, 2));
    console.log(formData);
    const updateResponse = await updateProfile(
      formData.first_name,
      formData.last_name,
      formData.phone,
      formData.zip,
      formData.birth_year,
      formData.gender,
      formData.marital_status,
      formData.education_level,
    );
    if (updateResponse) {
      console.log('authResponse: ', updateResponse);
      navigate("/activation-notification");
    } else {
      console.log("Show Errors - Alert")
    }
  };

  return (
    <>
      <section className="sign_up_main_wrapepr">
        <Container maxWidth="lg">
          <Box className="row">
            <Box className="left_section_sign">
              <Box className="signup__wrapper_left_section">
                <Box className="signup_heading">
                  <h2>Update Account</h2>
                </Box>
                  <Box className="sigup_form">
                    <Box>
                      <TextField
                        fullWidth
                        id="first_name"
                        name="first_name"
                        placeholder="Enter your first name"
                        label="First Name"
                        type="text"
                        variant="outlined"
                        value={getCurrentVal("FIRST_NAME")}
                        onChange={(e) => {
                          inputOnchange(e.target.value, "FIRST_NAME");
                        }}
                        margin="normal"
                      />
                    </Box>

                    {/* <AbsInput
                      placeholder="First Name"
                      type="text"
                      name="fname"
                      value={formData.fname}
                      onChange={(e) => handle(e)}
                    /> */}
                    <Box>
                      <TextField
                        fullWidth
                        id="last_name"
                        name="last_name"
                        placeholder="Enter your last name"
                        label="Last Name"
                        type="text"
                        variant="outlined"
                        value={getCurrentVal("LAST_NAME")}
                        onChange={(e) => {
                          inputOnchange(e.target.value, "LAST_NAME");
                        }}
                        margin="normal"
                      />
                    </Box>

                    {/* <AbsInput
                      placeholder="Last Name"
                      type="text"
                      name="lname"
                      value={formData.lname}
                      onChange={(e) => handle(e)}
                    /> */}
                    <Box>
                      <TextField
                        fullWidth
                        id="phone"
                        name="phone"
                        placeholder="Enter your phone number"
                        label="Phone"
                        type="text"
                        variant="outlined"
                        value={getCurrentVal("PHONE")}
                        onChange={(e) => {
                          inputOnchange(e.target.value, "PHONE");
                        }}
                        margin="normal"
                      />
                    </Box>

                    {/* <AbsInput
                      placeholder="Phone Number"
                      type="number"
                      name="number"
                      value={formData.number}
                      onChange={(e) => handle(e)}
                    /> */}
                    <Box>
                      <TextField
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="Enter your email address"
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={""}
                        // onChange={formik.handleChange}
                        margin="normal"
                        disabled
                      />
                    </Box>

                    <Box>
                      <TextField
                        fullWidth
                        id="zip"
                        name="zip"
                        placeholder="Enter your zip code"
                        label="zip"
                        type="text"
                        variant="outlined"
                        value={getCurrentVal("ZIP")}
                        onChange={(e) => {
                          inputOnchange(e.target.value, "ZIP");
                        }}
                        margin="normal"
                      />
                    </Box>

                    <Box>
                    <TextField
                  fullWidth
                  select
                  id="birth_year"
                  name="birth_year"
                  placeholder="Select your year of birth"
                  label="Year of Birth"
                  // type="text"
                  variant="outlined"
                  value={getCurrentVal("BIRTH_YEAR")}
                  onChange={(e) => {
                    inputOnchange(e.target.value, "BIRTH_YEAR");
                  }}
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"0"}>
                    No Selected
                  </MenuItem>
                  {BirthYearOptions.map((option) => (
                    <MenuItem key={option} value={`${option}`}>
                      {option}
                    </MenuItem>
                  ))}
                  <MenuItem selected key={"20001"} value={"20001"}>
                    {"20001"}
                  </MenuItem>
                </TextField>
                    </Box>

                    {/* <DropDown title1="Security Question 1" /> */}

                    <Box>
                    <TextField
                  fullWidth
                  select
                  id="gender"
                  name="gender"
                  placeholder="Select your gender"
                  label="Gender"
                  // type="text"
                  variant="outlined"
                  value={getCurrentVal("GENDER")}
                  onChange={(e) => {
                    inputOnchange(e.target.value, "GENDER");
                  }}
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"No Selected"}>
                    No Selected
                  </MenuItem>
                  {GenderOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                    </Box>

                    

                    <Box>
                    <TextField
                  fullWidth
                  select
                  id="marital_status"
                  name="marital_status"
                  placeholder="Select your marital status"
                  label="Marital Status"
                  // type="text"
                  variant="outlined"
                  value={getCurrentVal("MARITAL_STATUS")}
                  onChange={(e) => {
                    inputOnchange(e.target.value, "MARITAL_STATUS");
                  }}
                 
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"No Selected"}>
                    No Selected
                  </MenuItem>
                  {MaritalStatusOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                    </Box>
                    <Box>
                    <TextField
                  fullWidth
                  select
                  id="education_level"
                  name="education_level"
                  placeholder="Select your education level"
                  label="Education Level"
                  // type="text"
                  variant="outlined"
                  value={getCurrentVal("EDUCATION_LEVEL")}
                  onChange={(e) => {
                    inputOnchange(e.target.value, "EDUCATION_LEVEL");
                  }}
                  
                  margin="normal"
                >
                  <MenuItem key={"0"} value={"No Selected"}>
                    No Selected
                  </MenuItem>
                  {EducationLevelOptions.map((option) => (
                    <MenuItem key={option.id} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
                    </Box>

                    <Box className="buttons_section">
                      <Button className="sign_up_button" onClick={handleSubmit}>
                        Update My Account{" "}
                        <span className="arrow_icon">
                          <ArrowForwardIcon />
                        </span>
                      </Button>
                    </Box>
                  </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default Account;
