import React from "react";
import "../cashManagment/CashManagment.css";
import { Container, Box, Typography } from "@mui/material";
import PersonalFinancialIndex from "../personalFinancialIndex/PersonalFinancialIndex";
import RadioComponent from "../radioComponent/RadioComponent";
// import RangeSlider from "../rangeSlider/RangeSlider";
import PrevBUtton from "../prevButton/PrevBUtton";
import AbsButton from "../../components/absButton/AbsButton";
import { PFIFormConstants as PFIFC } from "../../constants/PFIFormConstants";
import NumInput from "../numInput/NumInput";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useDependentProtection } from "../../hooks/useDependentProtection";
import { getMyDependentProtection } from "../../apiServices/pfscoreForms/dependentProtection";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import allActions from "../../store/actions";

import { checkInitiatedScore } from "../../apiServices/pfscoreForms/financialHealth";
import { useNavigate } from "react-router-dom";

/**
 * DependentProtection Component
 * 
 * Displays a form for managing dependent protection insurance details.
 *
 * @param {Object} props - Component props
 * @param {Function} props.next - Callback function to navigate to the next step
 * @param {Function} props.pre - Callback function to navigate to the previous step
 * 
 * @returns {React.ReactElement} - The rendered DependentProtection component
 */

const DependentProtection = ({ next, pre }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { saveDependentProtection, isLoading, error } =
    useDependentProtection();

  const capitalizeUtil = function (word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  };
  // const CURR_CMP = "DependentProtection";
  const datafromreducer = useSelector(
    (state) => state.DependentProtectionReducer
  );
  const funcitonsformreducer = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchScoreStatus = async () => {
      try {
        const response = await checkInitiatedScore();
        if (!response?.checkInitiatedScore?.anyInitiatedScore) {
          navigate("/financial-health");
        }
      } catch (err) {
        console.log("Error while checking initiated score: ", err);
      }
    };

    fetchScoreStatus();
  }, []);

  const _handleNextPage = async (e) => {
    e.preventDefault();

    const updateDta = [
      parseInt(datafromreducer.ypOnetimeExp),
      parseInt(datafromreducer.ypYearsToSupport),
      parseInt(datafromreducer.ypAvgAnnualReturn),
      parseInt(datafromreducer.ypAvgInflation),
      datafromreducer.ypInsuranceSelfType.toUpperCase(),
      parseInt(datafromreducer.ypInsuranceSelf),
      parseInt(datafromreducer.ypInsuranceEmployer),
      datafromreducer.ypHaveHomeownersInsurance === "Yes" ? true : false,
      datafromreducer.ypHaveHealthInsurance === "Yes" ? true : false,
      datafromreducer.ypHaveLtDisabilityInsurance === "Yes" ? true : false,
      datafromreducer.ypHaveStDisabilityInsurance === "Yes" ? true : false,
      datafromreducer.ypHaveUmbrellaPolicy === "Yes" ? true : false,
      datafromreducer.ypHavePowerOfAttorney === "Yes" ? true : false,
      datafromreducer.ypHaveRevocableTrust === "Yes" ? true : false,
      datafromreducer.ypHaveLtCareInsurance === "Yes" ? true : false,
      datafromreducer.ypHaveWill === "Yes" ? true : false,
      datafromreducer.ypHaveLivingWill === "Yes" ? true : false,
    ];

    console.log("Update data is:");
    console.log(updateDta);

    setIsSaving(true);
    const response = await saveDependentProtection(updateDta);
    setIsSaving(false);

    if (response) {
      console.log("Save DependentProtection details Response: ", response);
      next();
    } else {
      console.log("Save DependentProtection details: Errors - Alert");
    }

    // next();
  };
  const _handleprev = (e) => {
    e.preventDefault();
    pre();
  };

  const inputOnchange = (value, type) => {
    funcitonsformreducer({
      type: type,
      payload: value,
    });
  };

  const getCurrentVal = (variable) => {
    // console.log(`${CURR_CMP}:: Getting current value for: ${variable}`);
    switch (variable) {
      // Insurance : Your Protection
      case "YP_ONETIME_EXP":
        return datafromreducer.ypOnetimeExp;
      case "YP_YEARS_TO_SUPPORT":
        return datafromreducer.ypYearsToSupport;
      case "YP_AVG_ANNUAL_RETURN":
        return datafromreducer.ypAvgAnnualReturn;
      case "YP_AVG_INFLATION":
        return datafromreducer.ypAvgInflation;
      case "YP_INSURANCE_SELF_TYPE":
        return datafromreducer.ypInsuranceSelfType;
      case "YP_INSURANCE_SELF":
        return datafromreducer.ypInsuranceSelf;
      case "YP_INSURANCE_EMPLOYER":
        return datafromreducer.ypInsuranceEmployer;
      case "YP_HAVE_HOMEOWNERS_INSURANCE":
        return datafromreducer.ypHaveHomeownersInsurance;
      case "YP_HAVE_HEALTH_INSURANCE":
        return datafromreducer.ypHaveHealthInsurance;
      case "YP_HAVE_LT_DISABILITY_INSURANCE":
        return datafromreducer.ypHaveLtDisabilityInsurance;
      case "YP_HAVE_ST_DISABILITY_INSURANCE":
        return datafromreducer.ypHaveStDisabilityInsurance;
      case "YP_HAVE_UMBRELLA_POLICY":
        return datafromreducer.ypHaveUmbrellaPolicy;
      case "YP_HAVE_POWER_OF_ATTORNEY":
        return datafromreducer.ypHavePowerOfAttorney;
      case "YP_HAVE_REVOCABLE_TRUST":
        return datafromreducer.ypHaveRevocableTrust;
      case "YP_HAVE_LT_CARE_INSURANCE":
        return datafromreducer.ypHaveLtCareInsurance;
      case "YP_HAVE_WILL":
        return datafromreducer.ypHaveWill;
      case "YP_HAVE_LIVING_WILL":
        return datafromreducer.ypHaveLivingWill;

      default:
        return 0;
    }
  };

  const handlePlusClick = (variable, step = 1) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(true, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  const handleMinusClick = (variable, step = 1) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(false, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  // Function is called everytime increment/decrement button is clicked
  const calculateNumValue = (order, currentVal = 0, step = 1) => {
    const minVal = 0;
    const maxVal = 1000000000000;
    const currVal = parseInt(currentVal);
    if (order) {
      // increment the value by step
      const incrVal = currVal + step;
      if (incrVal > maxVal) {
        return maxVal;
      } else {
        return incrVal;
      }
    } else {
      // decrement the value by step
      const decrVal = currVal - step;
      if (decrVal < minVal) {
        return minVal;
      } else {
        return decrVal;
      }
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      // try {
      const response = await getMyDependentProtection();
      const data = await response.getMyDependentProtection;
      setIsLoaded(true);
      // Assign default Values
      let updateData = {
        ypOnetimeExp:
          PFIFC.dependent_protection.insurance.onetime_expenses.default,
        ypYearsToSupport:
          PFIFC.dependent_protection.insurance.years_to_support.default,
        ypAvgAnnualReturn:
          PFIFC.dependent_protection.insurance.average_annual_return.default,
        ypAvgInflation:
          PFIFC.dependent_protection.insurance.annual_inflation_rate.default,
        ypInsuranceSelfType:
          PFIFC.dependent_protection.insurance.self_life_insurance_type.default,
        ypInsuranceSelf:
          PFIFC.dependent_protection.insurance.self_life_insurance.default,
        ypInsuranceEmployer:
          PFIFC.dependent_protection.insurance.employer_life_insurance.default,
        ypHaveHomeownersInsurance:
          PFIFC.dependent_protection.insurance.have_homeowners_insurance
            .default,
        ypHaveHealthInsurance:
          PFIFC.dependent_protection.insurance.have_health_insurance.default,
        ypHaveLtDisabilityInsurance:
          PFIFC.dependent_protection.insurance.longterm_disability_insurance
            .default,
        ypHaveStDisabilityInsurance:
          PFIFC.dependent_protection.insurance.shortterm_disability_insurance
            .default,
        ypHaveUmbrellaPolicy:
          PFIFC.dependent_protection.insurance.umbrella_policy.default,
        ypHavePowerOfAttorney: PFIFC.dependent_protection.insurance.poa.default,
        ypHaveRevocableTrust:
          PFIFC.dependent_protection.insurance.revocable_trust.default,
        ypHaveLtCareInsurance:
          PFIFC.dependent_protection.insurance.lt_care_ins.default,
        ypHaveWill: PFIFC.dependent_protection.insurance.will.default,
        ypHaveLivingWill:
          PFIFC.dependent_protection.insurance.living_will.default,
      };
      if (data.http_status === 200) {
        updateData = {
          ypOnetimeExp: parseInt(data.data.other_one_time_expenses),
          ypYearsToSupport: parseInt(
            data.data.exp_num_years_support_dependents
          ),
          ypAvgAnnualReturn: parseInt(
            data.data.exp_avg_annual_long_term_investment
          ),
          ypAvgInflation: parseInt(data.data.exp_annual_inflation_rate),
          ypInsuranceSelfType: capitalizeUtil(
            data.data.self_purchased_life_insurance_type
          ),
          ypInsuranceSelf: parseInt(
            data.data.self_purchased_life_insurance_amount
          ),
          ypInsuranceEmployer: parseInt(
            data.data.employer_provided_life_insurance_amount
          ),
          ypHaveHomeownersInsurance:
            data.data.have_homeowner_insurance === true ? "Yes" : "No",
          ypHaveHealthInsurance:
            data.data.have_health_insurance === true ? "Yes" : "No",
          ypHaveLtDisabilityInsurance:
            data.data.have_long_term_disability_ins === true ? "Yes" : "No",
          ypHaveStDisabilityInsurance:
            data.data.have_short_term_disability_ins === true ? "Yes" : "No",
          ypHaveUmbrellaPolicy:
            data.data.have_umbrella_policy === true ? "Yes" : "No",
          ypHavePowerOfAttorney:
            data.data.have_power_of_attorney === true ? "Yes" : "No",
          ypHaveRevocableTrust:
            data.data.have_revocable_trust === true ? "Yes" : "No",
          ypHaveLtCareInsurance:
            data.data.have_long_term_care_insurance === true ? "Yes" : "No",
          ypHaveWill: data.data.have_will === true ? "Yes" : "No",
          ypHaveLivingWill: data.data.have_living_will === true ? "Yes" : "No",
        };
      }
      console.log(`Retirement Initial update Data: ${updateData}`);
      console.log(updateData);

      funcitonsformreducer({
        type: "ALL_DP",
        payload: updateData,
      });

      if (data.score && data.score.overAllScore) {
        funcitonsformreducer(
          allActions.scoreActions.UpdateScore({
            overAllScore: data.score.overAllScore,
            debtScore: data.score.debtScore,
            riskScore: data.score.riskScore,
            savingsScore: data.score.savingsScore,
            debugInfo: JSON.stringify(data.score),
          })
        );
      }
      // } catch (err) {
      //   console.log("Initial Load Error:", err);
      //   setLoadingError(err.errors[0].message);
      // }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="cash_managment">
        <Container maxWidth="lg" className="cash_contain">
          <DetailsFormHeading
            title_text={"Dependent Protection"}
            subtitle_text={"Why? Needed to generate a Risk Management score."}
          />

          {/* The accuracy of your score is determined by the accuracy of your data. */}
          <Box className="cash_management_form">
            <form>
              <Box className="row cash_manag" sx={{ marginTop: "30px" }}>
                <Box className="cash_managment_sect">
                  <Typography variant="h6">Insurance</Typography>
                </Box>
              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      One-time expenses <br />
                      (wedding, etc)
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("YP_ONETIME_EXP")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "YP_ONETIME_EXP")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("YP_ONETIME_EXP", 1000)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("YP_ONETIME_EXP", 1000)
                      }
                      step={10000}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Expected number of years <br />
                      you expect to <br />
                      support dependents
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("YP_YEARS_TO_SUPPORT")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "YP_YEARS_TO_SUPPORT")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("YP_YEARS_TO_SUPPORT")
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("YP_YEARS_TO_SUPPORT")
                      }
                      placeholder=""
                      displayCurrency={false}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Expected average annual return on long-term investments
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("YP_AVG_ANNUAL_RETURN")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "YP_AVG_ANNUAL_RETURN")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("YP_AVG_ANNUAL_RETURN", 1)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("YP_AVG_ANNUAL_RETURN", 1)
                      }
                      placeholder=""
                      displayCurrency={false}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Expected annual inflation rate
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("YP_AVG_INFLATION")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "YP_AVG_INFLATION")
                      }
                      onPlusClick={(e) => handlePlusClick("YP_AVG_INFLATION")}
                      onMinusClick={(e) => handleMinusClick("YP_AVG_INFLATION")}
                      placeholder=""
                      displayCurrency={false}
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Self purchased life insurance amount
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <Box className="radio_web">
                      <RadioComponent
                        label1="Whole Life"
                        label2="Term Life"
                        value1="Whole"
                        value2="Term"
                        defaultValue={getCurrentVal("YP_INSURANCE_SELF_TYPE")}
                        handleRadioOption={(e) =>
                          inputOnchange(
                            e.target.value,
                            "YP_INSURANCE_SELF_TYPE"
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>
                <Box className="home_mortage_section">
                  <Box className="info_left_sec"></Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("YP_INSURANCE_SELF")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "YP_INSURANCE_SELF")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("YP_INSURANCE_SELF", 100)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("YP_INSURANCE_SELF", 100)
                      }
                      step={"100"}
                      placeholder=""
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
              </Box>
              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Employer provided life insurance <br /> amount
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("YP_INSURANCE_EMPLOYER")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "YP_INSURANCE_EMPLOYER")
                      }
                      onPlusClick={(e) =>
                        handlePlusClick("YP_INSURANCE_EMPLOYER", 100)
                      }
                      onMinusClick={(e) =>
                        handleMinusClick("YP_INSURANCE_EMPLOYER", 100)
                      }
                      placeholder=""
                      displayCurrency={true}
                      step="100"
                    />
                  </Box>
                </Box>
              </Box>

              <Box className="detail_sectionss2" sx={{ marginTop: "30px" }}>
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have homeowners' <br />
                    insurance?
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_HOMEOWNERS_INSURANCE")}
                    handleRadioOption={(e) =>
                      inputOnchange(
                        e.target.value,
                        "YP_HAVE_HOMEOWNERS_INSURANCE"
                      )
                    }
                  />
                </Box>
              </Box>

              <Box className="detail_sectionss2" sx={{ marginTop: "30px" }}>
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have health insurance?
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_HEALTH_INSURANCE")}
                    handleRadioOption={(e) =>
                      inputOnchange(e.target.value, "YP_HAVE_HEALTH_INSURANCE")
                    }
                  />
                </Box>
              </Box>

              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have long term disability ins.
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal(
                      "YP_HAVE_LT_DISABILITY_INSURANCE"
                    )}
                    handleRadioOption={(e) =>
                      inputOnchange(
                        e.target.value,
                        "YP_HAVE_LT_DISABILITY_INSURANCE"
                      )
                    }
                  />
                </Box>
              </Box>
              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have short term disability ins.
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal(
                      "YP_HAVE_ST_DISABILITY_INSURANCE"
                    )}
                    handleRadioOption={(e) =>
                      inputOnchange(
                        e.target.value,
                        "YP_HAVE_ST_DISABILITY_INSURANCE"
                      )
                    }
                  />
                </Box>
              </Box>
              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have an umbrella policy?
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_UMBRELLA_POLICY")}
                    handleRadioOption={(e) =>
                      inputOnchange(e.target.value, "YP_HAVE_UMBRELLA_POLICY")
                    }
                  />
                </Box>
              </Box>
              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have a Power of Attorney?
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_POWER_OF_ATTORNEY")}
                    handleRadioOption={(e) =>
                      inputOnchange(e.target.value, "YP_HAVE_POWER_OF_ATTORNEY")
                    }
                  />
                </Box>
              </Box>
              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have a revocable trust?
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_REVOCABLE_TRUST")}
                    handleRadioOption={(e) =>
                      inputOnchange(e.target.value, "YP_HAVE_REVOCABLE_TRUST")
                    }
                  />
                </Box>
              </Box>
              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have long term care insurance?
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_LT_CARE_INSURANCE")}
                    handleRadioOption={(e) =>
                      inputOnchange(e.target.value, "YP_HAVE_LT_CARE_INSURANCE")
                    }
                  />
                </Box>
              </Box>
              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">Do you have a Will?</Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_WILL")}
                    handleRadioOption={(e) =>
                      inputOnchange(e.target.value, "YP_HAVE_WILL")
                    }
                  />
                </Box>
              </Box>
              <Box className="detail_sectionss2">
                <Box className="info_left_sec2">
                  <Typography variant="body1">
                    Do you have a Living Will?
                  </Typography>
                </Box>
                <Box>
                  <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue={getCurrentVal("YP_HAVE_LIVING_WILL")}
                    handleRadioOption={(e) =>
                      inputOnchange(e.target.value, "YP_HAVE_LIVING_WILL")
                    }
                  />
                </Box>
              </Box>

              {/* Buttons */}
              <br />
              <br />
              <Box className="buttons_section">
                <PrevBUtton prev_name="Previous Page" onClick={_handleprev} />
                <AbsButton title="Next Page" onClick={_handleNextPage} />
              </Box>
            </form>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default DependentProtection;
