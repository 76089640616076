import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyPersonalDetails = async function () {
  console.log("Inside getMyPersonalDetails()");
  const { data, loading, error } = await gqlWebClient.query({
    query: gql`
      query {
        getMyPersonalDetails {
          code
          message
          http_status
          data {
            id
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
          }
        }
      }
    `,
    fetchPolicy: "no-cache" 
  });
  console.log("getMyPersonalDetails API Response is: ", data);
  console.log("getMyPersonalDetails API Response error is: ", error);

  return data;
};

export const addOrUpdatePersonalDetails = async function (
  planToSaveForCollege,
  ownHome,
  ownAdditionalProperties,
  ownBusiness,
  planForMajorPurchase,
  annualHouseholdIncome,
  valueOfInvestments,
  knowCreditScore,
  haveCollegeSavingPlan,
  knowValueOfHome
) {
  console.log("Inside addOrUpdatePersonalDetails()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addOrUpdatePersonalDetails(
        $planToSaveForCollege: Boolean!
        $ownHome: Boolean!
        $ownAdditionalProperties: Boolean!
        $ownBusiness: Boolean!
        $planForMajorPurchase: Boolean!
        $annualHouseholdIncome: Int!
        $valueOfInvestments: Int!
        $knowCreditScore: Boolean!
        $haveCollegeSavingPlan: Boolean!
        $knowValueOfHome: Boolean!
      ) {
        addOrUpdatePersonalDetails(
          data: {
            plan_to_save_for_college: $planToSaveForCollege
            own_home: $ownHome
            own_additional_properties: $ownAdditionalProperties
            own_business: $ownBusiness
            plan_for_major_purchase: $planForMajorPurchase
            annual_household_income: $annualHouseholdIncome
            value_of_investments: $valueOfInvestments
            know_credit_score: $knowCreditScore
            have_college_saving_plan: $haveCollegeSavingPlan
            know_value_of_home: $knowValueOfHome
          }
        ) {
          code
          message
          http_status
          data {
            id
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
            know_credit_score
            have_college_saving_plan
            know_value_of_home
            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            plan_to_save_for_college
            own_home
            own_additional_properties
            own_business
            plan_for_major_purchase
            annual_household_income
            value_of_investments
            know_credit_score
            have_college_saving_plan
            know_value_of_home
          }
        }
      }
    `,
    variables: {
      planToSaveForCollege,
      ownHome,
      ownAdditionalProperties,
      ownBusiness,
      planForMajorPurchase,
      annualHouseholdIncome,
      valueOfInvestments,
      knowCreditScore,
      haveCollegeSavingPlan,
      knowValueOfHome,
    },
  });

  console.log("addOrUpdatePersonalDetails API Response is: ", data);
  return data;
};
