import { PFIFormConstants as PFSFC } from "../../constants/PFIFormConstants"

// const pdInitialState = {
//     age: PFSFC.personal_details.details.age.default,
//     maritalStatus: PFSFC.personal_details.details.martial.default,
//     college: PFSFC.personal_details.details.college.default,
//     ownHome: PFSFC.personal_details.details.own_home.default,
//     addProperties: PFSFC.personal_details.details.add_properties.default,
//     business: PFSFC.personal_details.details.business.default,
//     majorPurchases: PFSFC.personal_details.details.major_pur.default,
//     houseHold: PFSFC.personal_details.details.income.default,
//     investment: PFSFC.personal_details.details.investment.default,
//     knowCreditScore: PFSFC.personal_details.details.know_credit_score.default,
//     haveCollegeSavingPlan: PFSFC.personal_details.details.have_college_saving_plan.default,
//     knowValueOfHome: PFSFC.personal_details.details.know_value_of_home.default
// };

const pdInitialState = {
    college: 'No',
    ownHome: 'No',
    addProperties: 'No',
    business: 'No',
    majorPurchases: 'No',
    houseHold: 14000,
    investment: 14000,
    knowCreditScore: 'No',
    haveCollegeSavingPlan: 'No',
    knowValueOfHome: 'No'
};

export const PersonalDetailsReducer = (state = pdInitialState, action) => {
    switch (action.type) {
        case "COLLEGE":
            return {
                ...state,
                college: (state.college = action.payload),
            };

        case "OWN_HOME":
            return {
                ...state,
                ownHome: (state.ownHome = action.payload),
            };

        case "ADD_PROPERTIES":
            return {
                ...state,
                addProperties: (state.addProperties = action.payload),
            };

        case "BUSINESS":
            return {
                ...state,
                business: (state.business = action.payload),
            };

        case "MAJOR_PURCHASES":
            return {
                ...state,
                majorPurchases: (state.majorPurchases = action.payload),
            };

        case "HOUSEHOLD_INCOME":
            return {
                ...state,
                houseHold: (state.houseHold = action.payload),
            };

        case "INVESTMENT":
            return {
                ...state,
                investment: (state.investment = action.payload),
            };

        case "KNOW_CREDIT_SCORE":
            return {
                ...state,
                knowCreditScore: (state.knowCreditScore = action.payload),
            };

        case "HAVE_COLLEGE_SAVING_PLAN":
            return {
                ...state,
                haveCollegeSavingPlan: (state.haveCollegeSavingPlan = action.payload),
            };

        case "KNOW_VALUE_OF_HOME":
            return {
                ...state,
                knowValueOfHome: (state.knowValueOfHome = action.payload),
            };

        case "ALL_PD":
            return {
                ...state,
                college: (state.college = action.payload.college),
                ownHome: (state.ownHome = action.payload.ownHome),
                addProperties: (state.addProperties = action.payload.addProperties),
                business: (state.business = action.payload.business),
                majorPurchases: (state.majorPurchases = action.payload.majorPurchases),
                houseHold: (state.houseHold = action.payload.houseHold),
                investment: (state.investment = action.payload.investment),
                knowCreditScore: (state.knowCreditScore = action.payload.knowCreditScore),
                haveCollegeSavingPlan: (state.haveCollegeSavingPlan = action.payload.haveCollegeSavingPlan),
                knowValueOfHome: (state.knowValueOfHome = action.payload.knowValueOfHome),
            };

        default:
            return state;
    }
};
