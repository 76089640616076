import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyScore = async function () {
  console.log("Inside getMyScore()");
  const { data } = await gqlWebClient.query({
    query: gql`
      {
        getMyScore {
          code
          message
          http_status
          data {
            overAllScore
            savingsScore
            debtScore
            riskScore
            progress
          }
          errors
        }
      }
    `,
    fetchPolicy: "no-cache"
  });
  console.log("getMyScore API Response is: ", data);
  return data;
};

export const getRanking = async function (filters = null) {
  console.log("Inside getPeerGroupScores()", filters);
  const { data } = await gqlWebClient.query({
    query: gql`
      query GetPeerGroupScores($filters: PeerGroupFiltersInput) {
        getPeerGroupScores(filters: $filters) {
          ranking
          percentile
          categoryDistribution {
            bottom
            low
            middle
            high
            highest
          }
        }
      }
    `,
    variables: { filters },
    fetchPolicy: "no-cache"
  });
  console.log("getPeerGroupScores API Response is: ", data);
  return data;
};

export const checkInitiatedScore = async function () {
  console.log("Inside checkInitiatedScore()");

  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        checkInitiatedScore {
          code
          message
          http_status
          errors
          anyInitiatedScore
        }
      }
    `,
    fetchPolicy: "no-cache",
  });

  console.log("checkInitiatedScore API Response is: ", data);
  return data;
};
export const initiatedScoreValues = async function () {
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        initiatedScoreDetail {
          code
          message
          http_status
          errors
          name
          description 
          isMainScore
        }
      }
    `,
    fetchPolicy: "no-cache",
  });
  return data;

}

export const getMyScorePdfUrl = async function () {
  console.log("Inside getMyScorePdfUrl()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyScorePdfUrl {
          downloadURL
          filename
        }
      }
    `,
  });
  console.log("getMyScorePdfUrl API Response is: ", data);
  return data;
};

export const getFilteredScores = async function (timePeriod) {
  console.log("Inside getFilteredScores()", timePeriod);
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation GetFilteredScores($filter: TimeFilterInput!) {
        getFilteredScores(filter: $filter) {
          code
          message
          http_status
          data {
            score
            formattedDate
          }
          errors
        }
      }
    `,
    variables: {
      filter: {
        timePeriod
      }
    }
  });
  console.log("getFilteredScores API Response is: ", data);
  return data;
};
