import React from "react";

// import AssumptionsDisc from "../../../components/assumptionsdisc/AssumptionsDisc";
import FooterImg from "../../../components/footerimg/FooterImg";
// import FinancialHelathHero from "../../../components/financialhelathhero/FinancialHelathHero";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";

import ScoreDisplayComponent from "../../../components/scoreDisplayComponent/ScoreDisplayComponent";
import {
  getMyScore,
  getRanking,
} from "../../../apiServices/pfscoreForms/financialHealth";
import { Typography, CircularProgress, Box } from "@mui/material";

const FinancialHealth = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const [ranking, setRanking] = useState("");

  // const CURR_CMP = "FinancialHealth";
  const datafromreducer = useSelector((state) => state.ScoreReducer);
  const funcitonsformreducer = useDispatch();

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      const ranking = await getRanking();
      console.log("Received Ranking data is: ");
      console.log(ranking.getPeerGroupScores.ranking);
      setRanking(ranking.getPeerGroupScores.ranking);
      // try {
      const response = await getMyScore();
      const data = await response.getMyScore;
      console.log("Received Response data s: ");
      console.log(data);
      setIsLoaded(true);

      // Assign default Values
      if (data.http_status === 200) {
        funcitonsformreducer({
          type: "SET_FINAL_SCORE",
          payload: {
            overAllScore: data.data.overAllScore,
            savingsScore: data.data.savingsScore,
            debtScore: data.data.debtScore,
            riskScore: data.data.riskScore,
            progress: data.data.progress,
            debugInfo: JSON.stringify(data.data.score),
          },
        });
      } else {
        setLoadingError("Error: Failed to Fetch the final score...");
      }

      // if (data.score && data.score.overAllScore) {
      //   funcitonsformreducer(
      //     allActions.scoreActions.UpdateScore({
      //       overAllScore: data.data.overAllScore,
      //     })
      //   );
      // }
      // } catch (err) {
      //   console.log("Initial Load Error:", err);
      //   setLoadingError(err.errors[0].message);
      // }
    };

    fetchData();
  }, [funcitonsformreducer]);

  return (
    <>
      <section>
        {/* <FinancialHelathHero />
        <AssumptionsDisc /> */}
        {isLoaded ? (
          <ScoreDisplayComponent
            overAllScore={datafromreducer.overAllScore}
            savingsScore={datafromreducer.savingsScore}
            debtScore={datafromreducer.debtScore}
            riskScore={datafromreducer.riskScore}
            debugInfo={datafromreducer.debugInfo}
            progress={datafromreducer.progress}
            ranking={ranking}
          />
        ) : (
          <Box 
            display="flex" 
            flexDirection="column" 
            alignItems="center" 
            justifyContent="center" 
            minHeight="200px"
            gap={2}
          >
            <CircularProgress size={40} />
            <Typography variant="h6" color="text.secondary">
              Loading your financial health score...
            </Typography>
          </Box>
        )}
        {loadingError ? { loadingError } : ""}
        <FooterImg />
      </section>
    </>
  );
};

export default FinancialHealth;
