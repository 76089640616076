/**
 * PdfReport Component
 * 
 * This component generates a PDF report based on the user's financial data.
 * It includes sections for personal details, net worth, cash management, retirement,
 * college education, and economic factors.
 * The report is generated using the @react-pdf/renderer library.
 */

import { Document, Page, Text, View, StyleSheet, Image } from "@react-pdf/renderer";
import headerlogo from "../../assets/images/header-logo.png";

const styles = StyleSheet.create({
  page: {
    padding: 30,
  },
  section: {
    marginBottom: 10,
  },
  title: {
    fontSize: 24,
    marginBottom: 20,
    fontWeight: 900,
  },
  heading: {
    fontSize: 18,
    marginBottom: 10,
    marginTop: 15,
    color:"#102691",
    fontWeight: 900,
  },
  scoreSection: {
    marginTop: 20,
    padding: 10,
    backgroundColor: "#F1FFFA",
  },
  scoreSectionHeading: {
    fontSize: 18,
    marginBottom: 10,
    marginTop: 15,
    color:"#00B962",
    fontWeight: 900,
  },
  row: {
    flexDirection: "row",
    marginBottom: 5,
  },
  label: {
    width: "60%",
    fontSize: 12,
  },
  value: {
    width: "40%",
    fontSize: 12,
  },
  currencyValue: {
    width: "40%",
    fontSize: 12,
  },
  subheading: {
    fontSize: 14,
    fontWeight: 800,
    marginTop: 15,
    marginBottom: 8,
    color: "#00A559",
    borderBottom: "1px solid #eee",
    paddingBottom: 4,
  },
  logoContainer: {
    marginBottom: 20,
    alignItems: 'center',
  },
  logo: {
    width: 200,  // Adjust width as needed
    height: 'auto',
  },
});

const formatCurrency = (value) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);
};

const PdfReport = ({ userData, scores }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.logoContainer}>
            <Image style={styles.logo} src={headerlogo} />
          </View>

          <Text style={styles.title}>Financial Health Report</Text>

          {/* Personal Details Section */}
          <Text style={styles.heading}>Personal Details</Text>

          <View style={styles.row}>
            <Text style={styles.label}>
              Plan to save for college education?
            </Text>
            <Text style={styles.value}>
              {userData.personalDetails?.college ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Do you own a home?</Text>
            <Text style={styles.value}>
              {userData.personalDetails?.ownHome ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Do you own additional properties?</Text>
            <Text style={styles.value}>
              {userData.personalDetails?.addProperties ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Do you own a business?</Text>
            <Text style={styles.value}>
              {userData.personalDetails?.business ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Plan to save for any major purchases?
            </Text>
            <Text style={styles.value}>
              {userData.personalDetails?.majorPurchases ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Approximate annual household income
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.personalDetails?.houseHold || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Approximate value of investments</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.personalDetails?.investment || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Do you know your credit score?</Text>
            <Text style={styles.value}>
              {userData.personalDetails?.knowCreditScore ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Do you have a 569 college savings plan?
            </Text>
            <Text style={styles.value}>
              {userData.personalDetails?.haveCollegeSavingPlan ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Do you know the value of your home?
            </Text>
            <Text style={styles.value}>
              {userData.personalDetails?.knowValueOfHome ? "Yes" : "No"}
            </Text>
          </View>

          {/* Net Worth Section */}
          <Text style={styles.heading}>Net Worth</Text>

          {/* Lifestyle Assets */}
          <Text style={styles.subheading}>Lifestyle Assets</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Market value of primary home</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.laPrimaryHome || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Market value of businesses and other assets
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.laPersonalValuables || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Market value of cars and other major items
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.laCarAndOthers || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Value of any other assets</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.laAnyOther || 0)}
            </Text>
          </View>

          {/* Savings and Investments */}
          <Text style={styles.subheading}>Savings and Investments</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Total Savings and Investments</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.siTotal || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Cash savings</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.siCash || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Current value of retirement accounts
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.siRetirementAc || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Value of all brokerage accounts</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.siBrokerageAc || 0)}
            </Text>
          </View>

          {/* Liabilities */}
          <Text style={styles.subheading}>Liabilities</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Mortgage on primary home?</Text>
            <Text style={styles.value}>
              {userData.netWorth?.isMortgagePrimaryHome ? "Yes" : "No"}
            </Text>
          </View>

          {userData.netWorth?.isMortgagePrimaryHome === "Yes" && (
            <>
              <View style={styles.row}>
                <Text style={styles.label}>Mortgage interest rate</Text>
                <Text style={styles.value}>
                  {userData.netWorth?.mortgageInterestRate || 0}%
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Years left on mortgage</Text>
                <Text style={styles.value}>
                  {userData.netWorth?.mortgageYearsLeft || 0} years
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Type of mortgage</Text>
                <Text style={styles.value}>
                  {userData.netWorth?.mortgageType || ""}
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Total mortgage years</Text>
                <Text style={styles.value}>
                  {userData.netWorth?.mortgageYearsTotal || ""} years
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>
                  Mortgage balance on primary home
                </Text>
                <Text style={styles.currencyValue}>
                  {formatCurrency(userData.netWorth?.lMortgage || 0)}
                </Text>
              </View>
            </>
          )}

          <View style={styles.row}>
            <Text style={styles.label}>
              Loan balances on cars and other major items
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.lCarsOthers || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Credit card debt and loan balances on major appliances
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.netWorth?.lCcDebtLoan || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Any other loans?</Text>
            <Text style={styles.value}>
              {userData.netWorth?.isOtherLoan || "No"}
            </Text>
          </View>

          {userData.netWorth?.isOtherLoan === "Yes" && (
            <View style={styles.row}>
              <Text style={styles.label}>Other loans amount</Text>
              <Text style={styles.currencyValue}>
                {formatCurrency(userData.netWorth?.lAnyOther || 0)}
              </Text>
            </View>
          )}

          {/* Cash Management Section */}
          <Text style={styles.heading}>Cash Management</Text>

          {/* Income */}
          <Text style={styles.subheading}>Income</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Salary/Bonus (Pre-tax)</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.iSalary || 0)} (
              {userData.cashManagement?.iSalaryPeriod || "Monthly"})
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Business Income</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.iRental || 0)} (
              {userData.cashManagement?.iRentalPeriod || "Monthly"})
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Any Other Income</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.iAnyOther || 0)} (
              {userData.cashManagement?.iAnyOtherPeriod || "Monthly"})
            </Text>
          </View>

          {/* Property Related Expenses */}
          <Text style={styles.subheading}>Property Related Expenses</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Home Mortgage Monthly Payment</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.prHomeMortgage || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Annual Property Taxes</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.prPropertyTax || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Annual Home Maintenance</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.prOther || 0)}
            </Text>
          </View>

          {/* Living Expenses */}
          <Text style={styles.subheading}>Living Expenses</Text>

          <View style={styles.row}>
            <Text style={styles.label}>
              Annual expenses on food, clothes, etc
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.leLife || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Health Insurance Payment</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.leHealthInsurance || 0)}{" "}
              ({userData.cashManagement?.leHealthInsurancePeriod || "Monthly"})
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Travel & Entertainment</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.leTravel || 0)} (
              {userData.cashManagement?.leTravelPeriod || "Monthly"})
            </Text>
          </View>

          {/* Other Expenses */}
          <Text style={styles.subheading}>Other Expenses</Text>

          <View style={styles.row}>
            <Text style={styles.label}>
              Annual estimated federal, state, and local taxes
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(
                userData.cashManagement?.oeEstimatedTaxesAnnual || 0
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Other Monthly Payments</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.cashManagement?.oeMonthlyOther || 0)}
            </Text>
          </View>

          {/* Retirement Section */}
          <Text style={styles.heading}>Retirement</Text>

          {/* Basic Information */}
          <Text style={styles.subheading}>Basic Information</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Years until retirement</Text>
            <Text style={styles.value}>
              {userData.retirement?.yearsToRetire || 0} years
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Expected retirement duration</Text>
            <Text style={styles.value}>
              {userData.retirement?.retirementDuration || 0} years
            </Text>
          </View>

          {/* Living Expenses */}
          <Text style={styles.subheading}>
            Living Expenses During Retirement
          </Text>

          <View style={styles.row}>
            <Text style={styles.label}>Housing expenses</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.leHousing || 0)} (
              {userData.retirement?.leHousingPeriod || "Monthly"})
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Healthcare expenses</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.healthcareExpenses || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Life expenses</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.lifeExpenses || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Travel & entertainment expenses</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.teExpenses || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Other expenses</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.otherExpenses || 0)}
            </Text>
          </View>

          {/* Expected Liabilities */}
          <Text style={styles.subheading}>
            Expected Liabilities During Retirement
          </Text>

          <View style={styles.row}>
            <Text style={styles.label}>Expected mortgage balance</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.elMortgage || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Expected other loans</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.elLoans || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Legacy amount</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.elLegacy || 0)}
            </Text>
          </View>

          {/* Retirement Savings */}
          <Text style={styles.subheading}>Retirement Savings</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Current retirement accounts value</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.rsRetirementAc || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Monthly employer contribution</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(
                userData.retirement?.rsContributionByEmployer || 0
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Your monthly contribution</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.rsContributionByYou || 0)}
            </Text>
          </View>

          {/* Social Security and Pensions */}
          <Text style={styles.subheading}>Social Security and Pensions</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Monthly pension benefit</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.isspPension || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Monthly Social Security benefit</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.retirement?.isspSocSec || 0)}
            </Text>
          </View>

          {/* Economic Factors */}
          <Text style={styles.subheading}>Economic Factors</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Expected inflation rate</Text>
            <Text style={styles.value}>
              {userData.retirement?.emfInflationRate || 0}%
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Expected annual return on retirement balance
            </Text>
            <Text style={styles.value}>
              {userData.retirement?.emfAnnualReturn || 0}%
            </Text>
          </View>

          {/* Education Section */}
          <Text style={styles.heading}>College Education</Text>

          {/* Basic Information */}
          <View style={styles.row}>
            <Text style={styles.label}>One year college cost</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.education?.oneYearCollegeCost || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Expected annual tuition inflation</Text>
            <Text style={styles.value}>
              {userData.education?.avgAnnualTuitionInflation || 0}%
            </Text>
          </View>

          {/* Children Details */}
          {userData.education?.children?.map((child, index) => (
            <View key={index}>
              <Text style={styles.subheading}>
                Child {index + 1}: {child.nameOfChild}
              </Text>

              <View style={styles.row}>
                <Text style={styles.label}>Age</Text>
                <Text style={styles.value}>{child.ageOfChild} years</Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Percentage of college cost</Text>
                <Text style={styles.value}>{child.percentOfCostForChild}%</Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Amount saved so far</Text>
                <Text style={styles.currencyValue}>
                  {formatCurrency(child.amountSavedSoFar)}
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Current monthly savings</Text>
                <Text style={styles.currencyValue}>
                  {formatCurrency(child.currentMonthlySavings)}
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>
                  Expected annual return on savings
                </Text>
                <Text style={styles.value}>{child.expectedAnnualReturn}%</Text>
              </View>
            </View>
          ))}

          {/* Major Purchases Section */}
          <Text style={styles.heading}>Major Purchases</Text>

          {userData.majorPurchases?.map((purchase, index) => (
            <View key={index}>
              <Text style={styles.subheading}>Purchase {index + 1}</Text>

              <View style={styles.row}>
                <Text style={styles.label}>Years until purchase</Text>
                <Text style={styles.value}>
                  {purchase.yearsToPurchase || 0} years
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Down payment needed</Text>
                <Text style={styles.currencyValue}>
                  {formatCurrency(purchase.amountNeeded || 0)}
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Amount saved</Text>
                <Text style={styles.currencyValue}>
                  {formatCurrency(purchase.amountSaved || 0)}
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Current monthly savings</Text>
                <Text style={styles.currencyValue}>
                  {formatCurrency(purchase.monthlySaving || 0)}
                </Text>
              </View>

              <View style={styles.row}>
                <Text style={styles.label}>Expected annual return</Text>
                <Text style={styles.value}>{purchase.annualReturn || 0}%</Text>
              </View>
            </View>
          ))}

          {/* Dependent Protection Section */}
          <Text style={styles.heading}>Dependent Protection</Text>

          {/* Insurance and Protection */}
          <Text style={styles.subheading}>Insurance and Protection</Text>

          <View style={styles.row}>
            <Text style={styles.label}>One-time expenses (wedding, etc)</Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(
                userData.dependentProtection?.onetimeExpenses || 0
              )}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Years to support dependents</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.yearsToSupport || 0} years
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Expected average annual return</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.avgAnnualReturn || 0}%
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Expected annual inflation rate</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.avgInflation || 0}%
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Life insurance type</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.insuranceSelfType || "Whole"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Self-purchased life insurance amount
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(userData.dependentProtection?.insuranceSelf || 0)}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Employer provided life insurance amount
            </Text>
            <Text style={styles.currencyValue}>
              {formatCurrency(
                userData.dependentProtection?.insuranceEmployer || 0
              )}
            </Text>
          </View>

          {/* Insurance Coverage */}
          <Text style={styles.subheading}>Insurance Coverage</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Have homeowners' insurance?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveHomeownersInsurance
                ? "Yes"
                : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Have health insurance?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveHealthInsurance ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Have long-term disability insurance?
            </Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveLtDisabilityInsurance
                ? "Yes"
                : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>
              Have short-term disability insurance?
            </Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveStDisabilityInsurance
                ? "Yes"
                : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Have umbrella policy?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveUmbrellaPolicy ? "Yes" : "No"}
            </Text>
          </View>

          {/* Legal Documents */}
          <Text style={styles.subheading}>Legal Documents</Text>

          <View style={styles.row}>
            <Text style={styles.label}>Have power of attorney?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.havePowerOfAttorney ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Have revocable trust?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveRevocableTrust ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Have long-term care insurance?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveLtCareInsurance ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Have will?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveWill ? "Yes" : "No"}
            </Text>
          </View>

          <View style={styles.row}>
            <Text style={styles.label}>Have living will?</Text>
            <Text style={styles.value}>
              {userData.dependentProtection?.haveLivingWill ? "Yes" : "No"}
            </Text>
          </View>

          {/* Scores Section */}
          <View style={styles.scoreSection}>
            <Text style={styles.scoreSectionHeading}>Your Financial Health Scores</Text>
            <View style={styles.row}>
              <Text style={styles.label}>Overall Score:</Text>
              <Text style={styles.value}>{scores.overAllScore}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Overall Score Percentage:</Text>
              <Text style={styles.value}>{scores.percentage}%</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Debt Score:</Text>
              <Text style={styles.value}>{scores.debtScore}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Risk Score:</Text>
              <Text style={styles.value}>{scores.riskScore}</Text>
            </View>
            <View style={styles.row}>
              <Text style={styles.label}>Savings Score:</Text>
              <Text style={styles.value}>{scores.savingsScore}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfReport;
