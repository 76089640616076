import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./Home.css";
import HeroSection from "../../components/hero/HeroSection";
import Personalfinancial from "../../components/personalfinancial/Personalfinancial";
import OpenModal from "../../components/modal/OpenModal";
import FooterImg from "../../components/footerimg/FooterImg";
import loadingImg from "../../assets/images/loader.gif";
import circle from "../../assets/images/hero-circle.png";
import { Container, Box } from "@mui/material";
import { Link } from "react-router-dom";
import GiftCardPopup from "../../components/giftCardPopup/GiftCardPopup";

import heroImage from "../../assets/images/hero-img.png";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [openGiftPopup, setOpenGiftPopup] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);

    // Show gift popup when component mounts
    setOpenGiftPopup(true);

    // Add timer to close popup after 10 seconds
    const popupTimer = setTimeout(() => {
      setOpenGiftPopup(false);
    }, 10000);

    // Cleanup timer on component unmount
    return () => clearTimeout(popupTimer);
  }, []);

  const handleCloseGiftPopup = () => {
    setOpenGiftPopup(false);
  };

  return (
    <>
      <Helmet>
        <title>Know Your Personal Financial Scores for Free with PFScores</title>
        <meta 
          name="description" 
          content="Take charge of your personal financial score with PFScores! A detailed assessment to benchmark and improve personal finance management for you and your family."
        />
      </Helmet>

      <section className="Home">
        {loading && (
          <div className="loader">
            <img src={loadingImg} alt="loading" />
          </div>
        )}
        <HeroSection title="A complete financial health checkup!" heroImage={heroImage} description="Check your proficiency in managing your personal finances. Personal Financial Scores (PFScores) is a comprehensive financial benchmark for individuals and families." />
        {/* <Container maxWidth="lg" className="hero-section">
        <Box className="row hero--second--row">
          <Box className="col hero--second--img">
            <img src={circle} className="circle" alt="" />
          </Box>
          <Box className="col hero--sec--right ">
            <h4 className="sub-tit">
              Just 15 minutes and you will have the result.
            </h4>
            <p>
              However, you need not complete it in one go as your information is
              automatically saved for future use.
            </p>
            <button className="show-detail">
              <Link to={"/"}>Show details</Link>
            </button>
          </Box>
        </Box>
        </Container> */}
        <Personalfinancial />
        <FooterImg />
        <GiftCardPopup 
          open={openGiftPopup}
          onClose={handleCloseGiftPopup}
        />
        {/*<OpenModal />*/}
      </section>
    </>
  );
};

export default Home;
