import { Container, Box, Typography } from "@mui/material";
import { BackDropEffect } from "../backDropEffect/BackDropEffect";
import BackLink from "../backLink/BackLink";
import CustomDropdown from "../CustomDropdown/CustomDropdown";
import { AuthConstants } from "../../constants/AuthConstants";
import { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import PropTypes from "prop-types";

/**
 * ScoreHistory Component
 * 
 * A visualization component that displays user ranking distribution in a bar chart format.
 * It shows the distribution of users across different ranking tiers (Bottom, Low, Middle, High, Highest)
 * and highlights the user's current position. Users can filter the distribution data using various 
 * demographic filters like age, education, gender, homeownership status, and state.
 * 
 * Props:
 * - ranking: Current user's ranking tier
 * - categoryDestribution: Distribution data across different tiers
 * - onFilterChange: Callback function to handle filter changes
 */

// Create a new Chart instance specifically for this component
const BarChart = Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ScoreHistory({ ranking, categoryDestribution, onFilterChange }) {
  console.log("categoryDestribution", categoryDestribution);
  // Get options from AuthConstants
  const ageOptions = AuthConstants.AgeOptions.map(
    (option) => option.name
  );
  const educationOptions = AuthConstants.EducationLevelOptions.map(
    (option) => option.name
  );
  const genderOptions = AuthConstants.GenderOptions.map(
    (option) => option.name
  );
  const stateOptions = AuthConstants.BirthYearOptions.getAllStates().map(
    (option) => option.name
  );
  const homeownerOptions = ["Yes", "No"];

  // Bar chart data with dynamic coloring based on ranking
  const barData = [
    {
      range: "1-20%",
      label: "Bottom",
      value: categoryDestribution.bottom,
      color: ranking === "Bottom" ? "#2C70FC" : "#3BE396",
    },
    {
      range: "21-40%",
      label: "Low",
      value: categoryDestribution.low,
      color: ranking === "Low" ? "#2C70FC" : "#3BE396",
    },
    {
      range: "41-60%",
      label: "Middle",
      value: categoryDestribution.middle,
      color: ranking === "Middle" ? "#2C70FC" : "#3BE396",
    },
    {
      range: "61-80%",
      label: "High",
      value: categoryDestribution.high,
      color: ranking === "High" ? "#2C70FC" : "#3BE396",
    },
    {
      range: "81-100%",
      label: "Highest",
      value: categoryDestribution.highest,
      color: ranking === "Highest" ? "#2C70FC" : "#3BE396",
    },
  ];

  // Find the current ranking bar to show tooltip
  const rankingBar = barData.find((bar) => bar.label === ranking);
  const rankingIndex = barData.findIndex((bar) => bar.label === ranking);

  // State to store selected values
  const [selectedValues, setSelectedValues] = useState({
    age: "",
    education: "",
    sex: "",
    homeowner: "",
    state: "",
  });

  // Add barThickness state
  const [barThickness, setBarThickness] = useState(80);

  // Add useEffect for responsive bar thickness
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 600) {         // Mobile
        setBarThickness(40);
      } else if (window.innerWidth < 960) {  // Tablet
        setBarThickness(60);
      } else {                               // Desktop
        setBarThickness(80);
      }
    };

    // Set initial thickness
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update handleSelect to send API request when filters change
  const handleSelect = (type, value) => {
    const newValues = {
      ...selectedValues,
      [type]: value,
    };
    setSelectedValues(newValues);

    // Convert the selected values to the format expected by the API
    const filters = {
      ageGroup: newValues.age ? mapAgeToGroup(newValues.age) : null,
      educationLevel: newValues.education || null,
      gender: newValues.sex || null,
      state: newValues.state || null,
      homeOwnership: newValues.homeowner ? newValues.homeowner === "Yes" : null,
    };

    // Only include non-null values
    const cleanFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, v]) => v !== null)
    );

    // Call the onFilterChange prop with the new filters
    onFilterChange(Object.keys(cleanFilters).length > 0 ? cleanFilters : null);
  };

  // Helper function to map age ranges to backend format
  const mapAgeToGroup = (age) => {
    const ageRanges = {
      "18-23": "18-23",
      "24-29": "24-29",
      "30-35": "30-35",
      "36-69": "36-69",
      "70+": "70+"
    };
    return ageRanges[age] || null;
  };

  // Chart.js configuration
  const chartData = {
    labels: barData.map((item) => item.label),
    datasets: [
      {
        data: barData.map((item) => item.value),
        backgroundColor: barData.map((item) => item.color),
        borderRadius: {
          topLeft: 8,
          topRight: 8,
          bottomLeft: 0,
          bottomRight: 0,
        },
        borderSkipped: false,
        barThickness: barThickness,
        maxBarThickness: barThickness,
        borderWidth: 0,
        barPercentage: 2,       // Controls the width of bars within category (max is 1.0)
    categoryPercentage: 2, 
      },
    ],
  };
  const max = (categoryDestribution.highest + categoryDestribution.high + categoryDestribution.middle + categoryDestribution.low + categoryDestribution.bottom)

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${barData[context.dataIndex].range}`;
          },
        },
      },
    },
    scales: {
      y: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        max: max,
        ticks: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: "#000000",
        },
        grid: {
          display: false,
          drawBorder: true,
        },
        border: {
          display: true,
          color: "#007636",
          width: 2,
        },
      },
    },
    barPercentage: 2,       // Controls the width of bars within category (max is 1.0)
    categoryPercentage: 2, 
  };

  return (
    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Typography
        sx={{ fontSize: "34px", fontWeight: 600, marginBottom: "50px" }}
      >
        Your Custom Ranking
      </Typography>
      <Box
        sx={{
            background: "radial-gradient(circle at bottom right, #D1FFEE, #F9FFFD)",
          borderRadius: {md:"36px", xs:"10px"},
          padding: {md:"24px", xs:"0px"},
          width: "100%",
          minHeight: "200px",
          marginBottom: "50px",
          paddingBottom: {md:"0px", xs:"30px"},
        }}
      >
        {/* Dropdowns Section */}
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", mb: 4 }}>
          <CustomDropdown
            defaultValue="Age"
            options={ageOptions}
            onSelect={(value) => handleSelect("age", value)}
          />
          <CustomDropdown
            defaultValue="Education"
            options={educationOptions}
            onSelect={(value) => handleSelect("education", value)}
          />
          <CustomDropdown
            defaultValue="Sex"
            options={genderOptions}
            onSelect={(value) => handleSelect("sex", value)}
          />
          <CustomDropdown
            defaultValue="Homeowner Status"
            options={homeownerOptions}
            onSelect={(value) => handleSelect("homeowner", value)}
          />
          <CustomDropdown
            defaultValue="State"
            options={stateOptions}
            onSelect={(value) => handleSelect("state", value)}
          />
        </Box>

        {/* Chart Section */}
        <Box sx={{ position: "relative", mt: 4 , overflow: 'hidden',}}>
          {/* Custom Tooltip */}
          {rankingBar && (
            <Box
              sx={{
                position: "absolute",
                top: `calc(100% - ${(rankingBar.value/max)*100}% - 105px)`,
                left: `calc(${barThickness === 40 ? "20" : barThickness === 60 ? "34" : "22"}% + ${rankingIndex * (70 / 5)}%)`,
                transform: "translateX(-50%)",
                bgcolor: "white",
                color: "black",
                p: 2,
                borderRadius: 2,
                boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
                zIndex: 1,
                maxWidth: "max-content",
                width: "170px",
                textAlign: "center",
               
              }}
            >
              <Typography>
                You are ranked in the {barData[rankingIndex].range} {ranking}{" "}
                Tier.
              </Typography>
            </Box>
          )}

          {/* Chart.js Bar Chart */}
          <Box
            sx={{
              height: "500px",
              width: {md:"70%", xs:"100%"},
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Bar data={chartData} options={chartOptions} />
          </Box>
        </Box>
      </Box>
    </Container>
  );
}

// Update PropTypes
ScoreHistory.propTypes = {
  ranking: PropTypes.string.isRequired,
  categoryDestribution: PropTypes.object.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default ScoreHistory;
