import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

export const getMyCashManagement = async function () {
  console.log("Inside getMyCashManagement()");
  const { data } = await gqlWebClient.query({
    query: gql`
      query {
        getMyCashManagement {
          code
          message
          http_status
          data {
            id
            salary_bonus_pretax_period
            salary_bonus_pretax_amount
            rental_income_period_period
            rental_income_amount
            any_other_income_period
            any_other_income_amount
            any_other_income_description
            home_mortgage_payment_period
            home_mortgage_payment_amount
            property_taxes_annual
            
            other_expenses_amount
            life_expenses_period
            life_expenses_amount
            health_insurance_payment_period
            health_insurance_payment_amount
            travel_entertainment_period
            travel_entertainment_amount
            estimated_taxes_annual
            any_other_monthly_payments_desc
            any_other_monthly_payments_amount

            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            salary_bonus_pretax_period
            salary_bonus_pretax_amount
            rental_income_period_period
            rental_income_amount
            any_other_income_period
            any_other_income_amount
            any_other_income_description
            home_mortgage_payment_period
            home_mortgage_payment_amount
            property_taxes_annual
            other_expenses_amount
            life_expenses_period
            life_expenses_amount
            health_insurance_payment_period
            health_insurance_payment_amount
            travel_entertainment_period
            travel_entertainment_amount
            estimated_taxes_annual
            any_other_monthly_payments_desc
            any_other_monthly_payments_amount
          }
        }
      }
    `,
    fetchPolicy: "no-cache" 
  });
  console.log("getMyCashManagement API Response is: ", data);
  return data;
};

export const addOrUpdateMyCashManagement = async function (
  salaryBonusPretaxPeriod,
  salaryBonusPretaxAmount,
  rentalIncomePeriodPeriod,
  rentalIncomeAmount,
  anyOtherIncomePeriod,
  anyOtherIncomeAmount,
  anyOtherIncomeDescription,
  homeMortgagePaymentPeriod,
  homeMortgagePaymentAmount,
  propertyTaxesAnnual,
  otherExpensesAmount,
  lifeExpensesPeriod,
  lifeExpensesAmount,
  healthInsurancePaymentPeriod,
  healthInsurancePaymentAmount,
  travelEntertainmentPeriod,
  travelEntertainmentAmount,
  estimatedTaxesAnnual,
  anyOtherMonthlyPaymentsDesc,
  anyOtherMonthlyPaymentsAmount
) {
  console.log(`salaryBonusPretaxPeriod: ${salaryBonusPretaxPeriod}`);
  console.log(`salaryBonusPretaxAmount: ${salaryBonusPretaxAmount}`);
  console.log(`rentalIncomePeriodPeriod: ${rentalIncomePeriodPeriod}`);
  console.log(`rentalIncomeAmount: ${rentalIncomeAmount}`);
  console.log(`anyOtherIncomeAmount: ${anyOtherIncomeAmount}`);
  console.log(`anyOtherIncomeDescription: ${anyOtherIncomeDescription}`);
  console.log(`homeMortgagePaymentPeriod: ${homeMortgagePaymentPeriod}`);
  console.log(`homeMortgagePaymentAmount: ${homeMortgagePaymentAmount}`);
  console.log(`propertyTaxesAnnual: ${propertyTaxesAnnual}`);
  console.log(`otherExpensesAmount: ${otherExpensesAmount}`);
  console.log(`lifeExpensesPeriod: ${lifeExpensesPeriod}`);
  console.log(`lifeExpensesAmount: ${lifeExpensesAmount}`);
  console.log(`healthInsurancePaymentPeriod: ${healthInsurancePaymentPeriod}`);
  console.log(`healthInsurancePaymentAmount: ${healthInsurancePaymentAmount}`);
  console.log(`travelEntertainmentPeriod: ${travelEntertainmentPeriod}`);
  console.log(`travelEntertainmentAmount: ${travelEntertainmentAmount}`);
  console.log(`estimatedTaxesAnnual: ${estimatedTaxesAnnual}`);
  console.log(`anyOtherMonthlyPaymentsDesc: ${anyOtherMonthlyPaymentsDesc}`);
  console.log(
    `anyOtherMonthlyPaymentsAmount: ${anyOtherMonthlyPaymentsAmount}`
  );

  console.log("Inside addOrUpdateMyCashManagement()");
  const { data } = await gqlWebClient.mutate({
    mutation: gql`
      mutation addOrUpdateMyCashManagement(
        $salaryBonusPretaxPeriod: Period!
        $salaryBonusPretaxAmount: Int!
        $rentalIncomePeriodPeriod: Period!
        $rentalIncomeAmount: Int!
        $anyOtherIncomePeriod: Period!
        $anyOtherIncomeAmount: Int!
        $anyOtherIncomeDescription: String!
        $homeMortgagePaymentPeriod: Period!
        $homeMortgagePaymentAmount: Int!
        $propertyTaxesAnnual: Int!
        $otherExpensesAmount: Int!
        $lifeExpensesPeriod: Period!
        $lifeExpensesAmount: Int!
        $healthInsurancePaymentPeriod: Period!
        $healthInsurancePaymentAmount: Int!
        $travelEntertainmentPeriod: Period!
        $travelEntertainmentAmount: Int!
        $estimatedTaxesAnnual: Int!
        $anyOtherMonthlyPaymentsDesc: String!
        $anyOtherMonthlyPaymentsAmount: Int!
      ) {
        addOrUpdateMyCashManagement(
          data: {
            salary_bonus_pretax_period: $salaryBonusPretaxPeriod
            salary_bonus_pretax_amount: $salaryBonusPretaxAmount
            rental_income_period_period: $rentalIncomePeriodPeriod
            rental_income_amount: $rentalIncomeAmount
            any_other_income_period: $anyOtherIncomePeriod
            any_other_income_amount: $anyOtherIncomeAmount
            any_other_income_description: $anyOtherIncomeDescription
            home_mortgage_payment_period: $homeMortgagePaymentPeriod
            home_mortgage_payment_amount: $homeMortgagePaymentAmount
            property_taxes_annual: $propertyTaxesAnnual
            other_expenses_amount: $otherExpensesAmount
            life_expenses_period: $lifeExpensesPeriod
            life_expenses_amount: $lifeExpensesAmount
            health_insurance_payment_period: $healthInsurancePaymentPeriod
            health_insurance_payment_amount: $healthInsurancePaymentAmount
            travel_entertainment_period: $travelEntertainmentPeriod
            travel_entertainment_amount: $travelEntertainmentAmount
            estimated_taxes_annual: $estimatedTaxesAnnual
            any_other_monthly_payments_desc: $anyOtherMonthlyPaymentsDesc
            any_other_monthly_payments_amount: $anyOtherMonthlyPaymentsAmount
          }
        ) {
          code
          message
          http_status
          data {
            id
            salary_bonus_pretax_period
            salary_bonus_pretax_amount
            rental_income_period_period
            rental_income_amount
            any_other_income_period
            any_other_income_amount
            any_other_income_description
            home_mortgage_payment_period
            home_mortgage_payment_amount
            property_taxes_annual
            other_expenses_amount
            life_expenses_period
            life_expenses_amount
            health_insurance_payment_period
            health_insurance_payment_amount
            travel_entertainment_period
            travel_entertainment_amount
            estimated_taxes_annual
            any_other_monthly_payments_desc
            any_other_monthly_payments_amount

            added_on
          }
          score {
            overAllScore
            savingsScore
            debtScore
            riskScore
          }
          errors {
            salary_bonus_pretax_period
            salary_bonus_pretax_amount
            rental_income_period_period
            rental_income_amount
            any_other_income_period
            any_other_income_amount
            any_other_income_description
            home_mortgage_payment_period
            home_mortgage_payment_amount
            property_taxes_annual
            other_expenses_amount
            life_expenses_period
            life_expenses_amount
            health_insurance_payment_period
            health_insurance_payment_amount
            travel_entertainment_period
            travel_entertainment_amount
            estimated_taxes_annual
            any_other_monthly_payments_desc
            any_other_monthly_payments_amount
          }
        }
      }
    `,
    variables: {
      salaryBonusPretaxPeriod: salaryBonusPretaxPeriod,
      salaryBonusPretaxAmount: salaryBonusPretaxAmount,
      rentalIncomePeriodPeriod: rentalIncomePeriodPeriod,
      rentalIncomeAmount: rentalIncomeAmount,
      anyOtherIncomePeriod: anyOtherIncomePeriod,
      anyOtherIncomeAmount: anyOtherIncomeAmount,
      anyOtherIncomeDescription: anyOtherIncomeDescription,
      homeMortgagePaymentPeriod: homeMortgagePaymentPeriod,
      homeMortgagePaymentAmount: homeMortgagePaymentAmount,
      propertyTaxesAnnual: propertyTaxesAnnual,
      otherExpensesAmount: otherExpensesAmount,
      lifeExpensesPeriod: lifeExpensesPeriod,
      lifeExpensesAmount: lifeExpensesAmount,
      healthInsurancePaymentPeriod: healthInsurancePaymentPeriod,
      healthInsurancePaymentAmount: healthInsurancePaymentAmount,
      travelEntertainmentPeriod: travelEntertainmentPeriod,
      travelEntertainmentAmount: travelEntertainmentAmount,
      estimatedTaxesAnnual: estimatedTaxesAnnual,
      anyOtherMonthlyPaymentsDesc: anyOtherMonthlyPaymentsDesc,
      anyOtherMonthlyPaymentsAmount: anyOtherMonthlyPaymentsAmount,
    },
  });

  console.log("addOrUpdateMyCashManagement API Response is: ", data);
  return data;
};
