import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuthContext } from '../../hooks/useAuthContext';
import { Link } from 'react-router-dom';

const GiftCardPopup = ({ open, onClose }) => {
  const { state } = useAuthContext();
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      PaperProps={{
        sx: {
          maxWidth: '800px',
          width: '100%',
          background: 'white',
          borderRadius: '20px',
          margin: '20px',
          position: 'relative'
        }
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#666'
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent sx={{ padding: {md:'40px', xs:'10px'}, display: 'flex', gap: { xs: 3, md: 6 } }}>
        <Box sx={{ width: '60%' }}>
          <Typography
            variant="h5"
            sx={{
              fontSize: { xs: '14px', md: '28px' },
              fontWeight: {xs: 500, md: 700},
              color: '#000',
              mb: 2
            }}
          >
            Generate Your Score & Win a Gift Card!
          </Typography>
          
          <Typography
            sx={{
              fontSize: { xs: '8px', md: '16px' },
              color: '#666',
              mb: 4,
              lineHeight: 1.5
            }}
          >
            Discover your custom score in just a few steps and stand a chance to win an exciting gift card. It's quick, easy, and rewarding!
          </Typography>

          <Button
            variant="contained"
            sx={{
              background: '#00FFA3',
              color: '#000',
              fontSize: { xs: '10px', md: '16px' },
              fontWeight: 600,
              padding: {md:'12px 24px'},
              borderRadius: '8px',
              textTransform: 'none',
              '&:hover': {
                background: '#00E69D'
              }
            }}
          >
            {state.user && (
                  <Link to="/scoring-details/personal-details">
                    Generate Your Score Now
                  </Link>
                )}
                {!state.user && (
                  <Link to="/signup">
                    Generate Your Score Now
                  </Link>
                )}
           
          </Button>
        </Box>

        <Box sx={{ 
         width: '40%'
        }}>
          <img
            src="/giftcard.png"
            alt="Gift Card Illustration"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain'
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default GiftCardPopup; 