/**
 * Custom hook for managing dependent protection data.
 * Provides functionality to load and save dependent protection information,
 * including storing data in localStorage and handling API interactions.
 * Used for managing insurance and protection plans for dependents.
 */

import { useContext, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { getMyDependentProtection, addOrUpdateMyDependentProtection } from '../apiServices/pfscoreForms/dependentProtection';

import { useNavigate } from "react-router-dom";

export const useDependentProtection= () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    const loadDependentProtection = async () => {
        setIsLoading(true);
        setError(null);
        const response = await getMyDependentProtection()
        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response && response.http_status!==200) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("loadDependentProtection Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('DependentProtection', response.getMyDependentProtection.data);
            setIsLoading(false);
            console.log("Feteched Initial Data for DependentProtection!!!");
        }
    }

    const saveDependentProtection = async (updateData) => {
        setIsLoading(true);
        setError(null);
        const response = await addOrUpdateMyDependentProtection(...updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response && response.http_status!==200) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("saveDependentProtection Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('DependentProtection', response.addOrUpdateMyDependentProtection.data);
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved DependentProtection details!!!");
        }
        return isSuccess
    }

    return {loadDependentProtection, saveDependentProtection, isLoading, error}
}