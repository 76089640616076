import React from "react";
import "./AbsButton.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

/**
 * A reusable button component that displays a title with a forward arrow icon
 * @param {Object} props
 * @param {string} props.title - The text to display on the button
 * @param {string} props.type - The button type (e.g., 'button', 'submit')
 * @param {Function} props.onClick - Click handler function
 */
const AbsButton = (props) => {
  const { title, type, onClick } = props;
  return (
    <>
      <button className="abs_button" type={type} onClick={onClick}>
        {title} <ArrowForwardIcon />
      </button>
    </>
  );
};

export default AbsButton;
