/**
 * ScoreDisplayComponent displays a user's financial health scores and metrics.
 * It shows:
 * - Overall financial score with star rating
 * - Ranking visualization and comparison
 * - Score history graph
 * - Detailed breakdown of subscores (debt, savings, risk management)
 * - Options to print/save report and add new scores
 */

import React from "react";
import {
  Stack,
  Button,
  Typography,
  Box,
  Container,
} from "@mui/material";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import StarRateIcon from "@mui/icons-material/StarRate";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getLatestScore } from "../../apiServices/pfscoreForms/completeScore";
import { generatePdf } from "../../utils/pdfGenerator";
import Divider from "@mui/material/Divider";
import { BackDropEffect } from "../../components/backDropEffect/BackDropEffect";
import BackLink from "../../components/backLink/BackLink";
import generatePDF from "react-to-pdf";
import headerLogo from "../../assets/images/header-logo.svg";
import {
  getUserPfiScores,
} from "../../apiServices/pfscoreForms/completeScore";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
} from "chart.js";
import SaveScoreModal from '../saveScoreModal/SaveScoreModal';

// Update the custom plugin
const customLinesPlugin = {
  id: "customLines",
  beforeDraw: (chart) => {
    const { ctx, chartArea, scales } = chart;
    if (!chartArea) return;

    const dataset = chart.data.datasets[0];
    const data = dataset.data;

    // Find min and max values and their indices
    const min = Math.min(...data);
    const max = Math.max(...data);

    ctx.setLineDash([8, 8]); // Increased gap between dots (was [5, 5])
    ctx.strokeStyle = "rgba(255, 255, 255, 0.5)";
    ctx.lineWidth = 3; // Increased line weight (was 1)

    // Draw horizontal min line
    ctx.beginPath();
    ctx.moveTo(chartArea.left, scales.y.getPixelForValue(min));
    ctx.lineTo(chartArea.right, scales.y.getPixelForValue(min));
    ctx.stroke();

    // Draw horizontal max line
    ctx.beginPath();
    ctx.moveTo(chartArea.left, scales.y.getPixelForValue(max));
    ctx.lineTo(chartArea.right, scales.y.getPixelForValue(max));
    ctx.stroke();

    // Reset line style
    ctx.setLineDash([]);
  },
};

// Register the plugin
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  customLinesPlugin
);

const GradientDivider = styled(Divider)(({ theme }) => ({
  border: "1px solid transparent",
  background: `
    linear-gradient(#000, #000) padding-box,
    linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box
  `,
  margin: theme.spacing(2, 0),
}));

function IndividualScoreDisplayComponent(props) {
  return (
    <Grid container spacing={2} sx={{ marginTop: "30px" }}>
      <Grid item xs={12} md={2}>
        <Box
          sx={{
            borderRadius: "10px",
            width: "86px",
            height: "60px",
            border: "2px solid transparent",
            background:
              "linear-gradient(#000, #000) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box",
          }}
        >
          <Typography
            sx={{
              color: "#FFC700",
              fontSize: "28px",
              fontWeight: 700,
              textAlign: "center",
              marginTop: "17px",
            }}
          >
            {props.score}
          </Typography>
        </Box>
        <Typography
          sx={{
            color: "#A0A0A0",
            fontSize: "12px",
            fontWeight: 400,
            textAlign: "center",
            width: "86px",
          }}
        >
          Score of {props.maxScore}
        </Typography>
      </Grid>
      <Grid item xs={12} md={10}>
        <Typography
          sx={{
            fontSize: "20px",
            fontWeight: 700,
            background:
              "linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            display: "inline",
          }}
        >
          {props.title}
        </Typography>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "14px",
            fontWeight: 300,
            lineHeight: "2.2rem",
            marginTop: "10px",
          }}
        >
          {props.description}
        </Typography>
      </Grid>
    </Grid>
  );
}

const getTargetElement = () => {
  let scorePanel = document.getElementById("score-panel");
  return scorePanel;
};

function ScoreDisplayComponent(props) {
  // Add this function to handle ranking conversion
  const getRankingText = (ranking) => {
    switch (ranking) {
      case "Bottom":
        return "Low 0-20%";
      case "Low":
        return "Low 21-40%";
      case "Middle":
        return "Middle 41-60%";
      case "High":
        return "High 61-80%";
      case "Highest":
        return "Top 81-100%";
      default:
        return "";
    }
  };

    const handleDownloadReport = async () => {
      let scoreData;
      try {
        const response = await getLatestScore();
        if (response.getLatestScore.code === "SUCCESS") {
          scoreData = response.getLatestScore.data;
        } else {
          console.log("error in fetching:", response.getLatestScore.message);
          return;
        }
      } catch (err) {
        console.log("Failed to fetch score data");
        console.error(err);
        return;
      }
  
      if (!scoreData) return;
  
      const userData = {
        personalDetails: {
          college: scoreData.personalDetails.plan_to_save_for_college,
          ownHome: scoreData.personalDetails.own_home,
          addProperties: scoreData.personalDetails.own_additional_properties,
          business: scoreData.personalDetails.own_business,
          majorPurchases: scoreData.personalDetails.plan_for_major_purchase,
          houseHold: scoreData.personalDetails.annual_household_income,
          investment: scoreData.personalDetails.value_of_investments,
          knowCreditScore: scoreData.personalDetails.know_credit_score,
          haveCollegeSavingPlan: scoreData.personalDetails.have_college_saving_plan,
          knowValueOfHome: scoreData.personalDetails.know_value_of_home,
        },
        netWorth: {
          // Lifestyle Assets
          laPrimaryHome: scoreData.netWorth.market_value_primary_home,
          laCarAndOthers: scoreData.netWorth.market_value_cars_major_items,
          laPersonalValuables: scoreData.netWorth.market_value_personal_valuables,
          laAnyOther: scoreData.netWorth.any_other_assets_amount,
  
          // Savings and Investments
          siTotal: scoreData.netWorth.total_savings,
          siCash: scoreData.netWorth.cash_savings,
          siRetirementAc: scoreData.netWorth.current_value_retirement_accounts,
          siBrokerageAc: scoreData.netWorth.value_all_brokerage_accounts,
  
          // Liabilities
          isMortgagePrimaryHome: scoreData.netWorth.is_mortgage_primary_home,
          mortgageInterestRate: scoreData.netWorth.mortgage_interest_rate,
          mortgageYearsLeft: scoreData.netWorth.mortgage_years_left,
          mortgageType: scoreData.netWorth.mortgage_type,
          mortgageYearsTotal: scoreData.netWorth.mortgage_years_total,
          lMortgage: scoreData.netWorth.mortgage_balance_primary_home,
          lCarsOthers: scoreData.netWorth.loan_balance_cars_other_major_items,
          lCcDebtLoan: scoreData.netWorth.credit_card_debt_loan_balance,
          isOtherLoan: scoreData.netWorth.is_other_loan,
          lAnyOther: scoreData.netWorth.any_other_loans_amount,
        },
        cashManagement: {
          // Income
          iSalary: scoreData.cashManagement.salary_bonus_pretax_amount,
          iSalaryPeriod: scoreData.cashManagement.salary_bonus_pretax_period,
          iRental: scoreData.cashManagement.rental_income_amount,
          iRentalPeriod: scoreData.cashManagement.rental_income_period_period,
          iAnyOther: scoreData.cashManagement.any_other_income_amount,
          iAnyOtherPeriod: scoreData.cashManagement.any_other_income_period,
          iAnyOtherDesc: scoreData.cashManagement.any_other_income_description,
  
          // Property Related Expenses
          prHomeMortgage: scoreData.cashManagement.home_mortgage_payment_amount,
          prHomeMortgagePeriod: scoreData.cashManagement.home_mortgage_payment_period,
          prPropertyTax: scoreData.cashManagement.property_taxes_annual,
          prOther: scoreData.cashManagement.other_expenses_amount,
  
          // Living Expenses
          leLife: scoreData.cashManagement.life_expenses_amount,
          leLifePeriod: scoreData.cashManagement.life_expenses_period,
          leHealthInsurance: scoreData.cashManagement.health_insurance_payment_amount,
          leHealthInsurancePeriod: scoreData.cashManagement.health_insurance_payment_period,
          leTravel: scoreData.cashManagement.travel_entertainment_amount,
          leTravelPeriod: scoreData.cashManagement.travel_entertainment_period,
  
          // Other Expenses
          oeEstimatedTaxesAnnual: scoreData.cashManagement.estimated_taxes_annual,
          oeMonthlyOther: scoreData.cashManagement.any_other_monthly_payments_amount,
          oeMonthlyOtherDesc: scoreData.cashManagement.any_other_monthly_payments_desc,
        },
        retirement: {
          yearsToRetire: scoreData.retirement.number_of_years_left_to_retire,
          retirementDuration: scoreData.retirement.expected_duration_of_retirement,
          leHousing: scoreData.retirement.housing_expenses_amount,
          leHousingPeriod: scoreData.retirement.housing_expenses_period,
          healthcareExpenses: scoreData.retirement.healthcare_expenses,
          lifeExpenses: scoreData.retirement.life_expenses,
          teExpenses: scoreData.retirement.te_expenses,
          otherExpenses: scoreData.retirement.other_expenses,
          elMortgage: scoreData.retirement.expected_mortgage_at_retirement,
          elLoans: scoreData.retirement.other_expected_loans_at_retirement,
          elLegacy: scoreData.retirement.legacy_you_want_to_leave,
          rsRetirementAc: scoreData.retirement.all_retirement_accounts_value,
          rsContributionByEmployer: scoreData.retirement.average_monthly_contribution_by_employer,
          rsContributionByYou: scoreData.retirement.monthly_contribution,
          isspPension: scoreData.retirement.estimated_monthly_pension_benefit,
          isspSocSec: scoreData.retirement.estimated_monthly_soc_sec_benefit,
          emfInflationRate: scoreData.retirement.expected_avg_inflation_rate,
          emfAnnualReturn: scoreData.retirement.expected_avg_annual_return_retirement_balance,
        },
        education: {
          oneYearCollegeCost: scoreData.education.one_year_college_cost,
          avgAnnualTuitionInflation: scoreData.education.avg_annual_tuition_inflation,
          children: scoreData.education.children.map((child) => ({
            nameOfChild: child.name_of_child,
            ageOfChild: child.age_of_child,
            percentOfCostForChild: child.percent_of_cost_for_child,
            amountSavedSoFar: child.amount_saved_so_far,
            currentMonthlySavings: child.current_monthly_savings_for_child,
            expectedAnnualReturn: child.expected_annual_return_on_savings,
            totalMonthlyContributionRequired: child.total_monthly_contribution_required,
          })),
        },
        majorPurchases: scoreData.majorPurchases.map((purchase) => ({
          yearsToPurchase: purchase.num_years_left_for_purchase,
          amountNeeded: purchase.amount_needed_as_full_down_payment,
          amountSaved: purchase.amount_saved_so_far_for_purchase,
          monthlySaving: purchase.current_monthly_savings_for_purchase,
          annualReturn: purchase.exp_avg_annual_return_on_savings,
          nameOfPurchase: purchase.name_of_purchase,
        })),
        dependentProtection: {
          // Insurance and Protection
          onetimeExpenses: scoreData.dependentProtection.other_one_time_expenses,
          yearsToSupport: scoreData.dependentProtection.exp_num_years_support_dependents,
          avgAnnualReturn: scoreData.dependentProtection.exp_avg_annual_long_term_investment,
          avgInflation: scoreData.dependentProtection.exp_annual_inflation_rate,
          insuranceSelfType: scoreData.dependentProtection.self_purchased_life_insurance_type,
          insuranceSelf: scoreData.dependentProtection.self_purchased_life_insurance_amount,
          insuranceEmployer: scoreData.dependentProtection.employer_provided_life_insurance_amount,
  
          // Insurance Coverage
          haveHomeownersInsurance: scoreData.dependentProtection.have_homeowner_insurance,
          haveHealthInsurance: scoreData.dependentProtection.have_health_insurance,
          haveLtDisabilityInsurance: scoreData.dependentProtection.have_long_term_disability_ins,
          haveStDisabilityInsurance: scoreData.dependentProtection.have_short_term_disability_ins,
          haveUmbrellaPolicy: scoreData.dependentProtection.have_umbrella_policy,
  
          // Legal Documents
          havePowerOfAttorney: scoreData.dependentProtection.have_power_of_attorney,
          haveRevocableTrust: scoreData.dependentProtection.have_revocable_trust,
          haveLtCareInsurance: scoreData.dependentProtection.have_long_term_care_insurance,
          haveWill: scoreData.dependentProtection.have_will,
          haveLivingWill: scoreData.dependentProtection.have_living_will,
        },
      };
  
      const scores = {
        percentage: Number(((scoreData.score / 750) * 100).toFixed(2)),
        overAllScore: scoreData.score,
        debtScore: scoreData.debtScore,
        riskScore: scoreData.riskScore,
        savingsScore: scoreData.savingsScore,
      };
  
      await generatePdf(userData, scores);
    };

  // function generateReport() {
  //   document.getElementById("report-panel").style.display = "flex";
  //   document.getElementById("score-panel").style.width = "1200px";
  //   generatePDF(getTargetElement, { filename: "report.pdf" });
  //   document.getElementById("score-panel").style.width = "auto";
  //   document.getElementById("report-panel").style.display = "none";
  // }
  const navigate = useNavigate();
  const [scoreData, setScoreData] = useState([]);
  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const [openSaveModal, setOpenSaveModal] = useState(false);
  const [openNewScoreModal, setOpenNewScoreModal] = useState(false);

  const handleOpenSaveModal = () => setOpenSaveModal(true);
  const handleCloseSaveModal = () => setOpenSaveModal(false);
  const handleOpenNewScoreModal = () => setOpenNewScoreModal(true);
  const handleCloseNewScoreModal = () => setOpenNewScoreModal(false);


  useEffect(() => {
    const fetchScores = async () => {
      try {
        const response = await getUserPfiScores();
        console.log("response", response);
        if (response.getUserPfiScores.code === "SUCCESS") {
          // Extract scores, reverse the array, and take only the last 7 values
          const scores = response.getUserPfiScores.data
            .map((item) => item.score)
            .reverse()
            .slice(0, 7); // Take only the last 7 scores
          setScoreData(scores);
        }
      } catch (error) {
        console.error("Error fetching scores:", error);
      }
    };

    fetchScores();
  }, []);
  useEffect(() => {
    if (props.progress === "complete" || props.progress === undefined) {
      setIsCreatingNew(true);
    }
  }, [props.progress]);

  // Update the chart data configuration to ensure consistent spacing
  const chartData = {
    labels: Array(7).fill(""), // Always create 7 empty labels
    datasets: [
      {
        data: scoreData,
        fill: true,
        borderColor: "#4EEEB3",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.top,
            0,
            chartArea.bottom
          );
          gradient.addColorStop(0, "rgba(47, 238, 168, 0.3)");
          gradient.addColorStop(1, "rgba(88, 247, 188, 0)");
          return gradient;
        },
        tension: 0,
        pointBackgroundColor: "#4EEEB3",
        pointBorderColor: "#FFFFFF",
        pointBorderWidth: 3,
        pointRadius: 6,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (context) {
            return `Score: ${context.parsed.y}`;
          },
        },
      },
      customLines: {
        enabled: true,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        min: Math.min(...(scoreData.length ? scoreData : [0])) - 50,
        max: Math.max(...(scoreData.length ? scoreData : [0])) + 50,
      },
    },
    elements: {
      line: {
        borderWidth: 4,
      },
    },
  };

  const isLowRanking = props.ranking === "Bottom" || props.ranking === "Low";

  // Add this state for modal control


  return (
    <Container maxWidth="lg">
      <BackDropEffect />
      <BackLink />
      <Box>
        <DetailsFormHeading
          title_text={"Your Score"}
          subtitle_text={"This is not advice. Click on our Disclaimer below."}
        />
      </Box>

      <Box
        id="score-panel"
        sx={{
          backgroundImage: "linear-gradient(180deg, #000 0%, #000B40 100%)",
          borderRadius: "10px",
          padding: { lg: "60px", xs: "15px" },
          marginTop: "40px",
        }}
      >
        <Stack
          id="report-panel"
          sx={{ marginBottom: "60px", display: "none" }}
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <img src={headerLogo} alt="header_logo" />
          <Typography sx={{ color: "white" }}>
            This is not financial advice. See our full Disclaimer Policy{" "}
            {process.env.REACT_APP_PUBLIC_URL + "disclaimer"}
          </Typography>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                borderRadius: "10px",
                border: "2px solid transparent",
                paddingX: "30px",
                height: { lg: "660px", md: "660px", xs: "680px" },
                background:
                  "linear-gradient(#000, #000) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box",
              }}
            >
              <Box
                sx={{
                  borderRadius: "10px",
                  width: "140px",
                  height: "60px",
                  margin: "-32px auto 0 auto",
                  border: "2px solid transparent",
                  background:
                    "linear-gradient(#000, #000) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box",
                }}
              >
                <Typography
                  sx={{
                    color: "#FFC700",
                    fontSize: "35px",
                    fontWeight: 700,
                    textAlign: "center",
                    marginTop: "17px",
                  }}
                >
                  {props.overAllScore}
                </Typography>
              </Box>
              <Stack
                direction="row"
                sx={{
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "28px",
                }}
              >
                {props.overAllScore < 301 && props.overAllScore > 0 && (
                  <StarRateIcon
                    sx={{
                      color: "#FFC700",
                      transform: "scale(1.5)",
                    }}
                  />
                )}
                {props.overAllScore < 401 && props.overAllScore > 300 && (
                  <>
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                  </>
                )}

                {props.overAllScore < 551 && props.overAllScore > 400 && (
                  <>
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                  </>
                )}

                {props.overAllScore < 651 && props.overAllScore > 550 && (
                  <>
                    {" "}
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1,
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1,
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1,
                      }}
                    />
                  </>
                )}

                {props.overAllScore > 650 && (
                  <>
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                    <StarRateIcon
                      sx={{
                        color: "#FFC700",
                        transform: "scale(1.5)",
                        marginRight: 1, // 1.5 times the original size
                      }}
                    />
                  </>
                )}
              </Stack>
              <Typography
                sx={{
                  color: "white",
                  textAlign: "center",
                  fontSize: "26px",
                  marginTop: "28px",
                  fontWeight: 600,
                  marginBottom: "30px",
                }}
              >
                {props.overAllScore > 650 ? "Outstanding!" : ""}
                {props.overAllScore < 651 && props.overAllScore > 550
                  ? "Good!"
                  : ""}
                {props.overAllScore < 551 && props.overAllScore > 400
                  ? "Average!"
                  : ""}
                {props.overAllScore < 401 && props.overAllScore > 300
                  ? "Fair!"
                  : ""}
                {props.overAllScore < 301 && props.overAllScore > 0
                  ? "Needs improvement"
                  : ""}
              </Typography>

              <GradientDivider />
              <Box sx={{ position: "relative", marginTop: "120px" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                    gap: "30px",
                  }}
                >
                  <Box sx={{ position: "relative" }}>
                    {/* Tooltip for small box - only show for Bottom or Low ranking */}
                    {isLowRanking && getRankingText(props.ranking) !== "" && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "100%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          background:
                            "linear-gradient(180deg, #00157B, rgba(16, 38, 145, 0.3))",
                          color: "white",
                          padding: {
                            lg: "12px 16px",
                            md: "10px 12px",
                            sm: "8px 10px",
                            xs: "6px 8px",
                          },
                          borderRadius: "8px",
                          marginBottom: "12px",
                          width: {
                            xl: "140px",
                            lg: "140px",
                            md: "120px",
                            sm: "100px",
                            xs: "90px",
                          },
                          zIndex: 1,
                          "&:after": {
                            content: '""',
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            borderWidth: "8px",
                            borderStyle: "solid",
                            borderColor:
                              "rgba(16, 38, 145, 0.3) transparent transparent transparent",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: {
                              lg: "16px",
                              md: "14px",
                              sm: "12px",
                              xs: "11px",
                            },
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          You are ranked in the {getRankingText(props.ranking)}
                        </Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        width: "70px",
                        height: "170px",
                        background:
                          "linear-gradient(180deg, #4B4B4B 0%, #00051E 100%)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </Box>
                  <Box sx={{ position: "relative" }}>
                    {/* Tooltip for large box - show for all rankings */}
                    {!isLowRanking && getRankingText(props.ranking) !== "" && (
                      <Box
                        sx={{
                          position: "absolute",
                          bottom: "100%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          background:
                            "linear-gradient(180deg, #00157B, rgba(16, 38, 145, 0.3))",
                          color: "white",
                          padding: {
                            lg: "12px 16px",
                            md: "10px 12px",
                            sm: "8px 10px",
                            xs: "6px 8px",
                          },
                          borderRadius: "8px",
                          marginBottom: "12px",
                          width: {
                            xl: "180px",
                            lg: "160px",
                            md: "140px",
                            sm: "120px",
                            xs: "100px",
                          },
                          zIndex: 1,
                          "&:after": {
                            content: '""',
                            position: "absolute",
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            borderWidth: "8px",
                            borderStyle: "solid",
                            borderColor:
                              "rgba(16, 38, 145, 0.3) transparent transparent transparent",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            color: "white",
                            fontSize: {
                              lg: "16px",
                              md: "14px",
                              sm: "12px",
                              xs: "11px",
                            },
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          You are ranked in the {getRankingText(props.ranking)}
                        </Typography>
                      </Box>
                    )}

                    <Box
                      sx={{
                        width: "70px",
                        height: "280px",
                        background:
                          "linear-gradient(180deg, #2C70FC 0%, #00051E 100%)",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      }}
                    />
                  </Box>
                </Box>
                <Divider
                  sx={{
                    bgcolor: " #007636",
                    height: "2px",
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    right: "0",
                  }}
                />
              </Box>
              <Button
                onClick={() => navigate("/ranking")}
                sx={{
                  background: "linear-gradient(to right, #2C70FC , #5BA8F6)",
                  color: "#000831",
                  fontWeight: 600,
                  fontSize: { lg: "16px", md: "12px", sm: "12px" },
                  padding: { lg: "10px 20px", md: "8px 12px", sm: "6px 10px" },
                  borderRadius: "5px",
                  marginTop: "20px",
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                View Your Custom Ranking
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { md: "column", xs: "column-reverse" },
              }}
            >
              <Box sx={{ marginLeft: { md: "80px" } }}>
                <Typography
                  sx={{
                    color: "#FFF",
                    fontSize: { md: "30px", xs: "35px" },
                    fontWeight: { md: 500, xs: 700 },
                    marginBottom: "30px",
                    marginTop: { md: "0px", xs: "20px" },
                    lineHeight: "normal",
                  }}
                >
                  Your Personal Financial Health Score
                </Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    onClick={handleDownloadReport}
                    sx={{
                      background: 'linear-gradient(266.57deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)',
                      borderRadius: '10px',
                      padding: {md:'13px 50px'},
                      fontFamily: '"Inter", sans-serif',
                      fontSize: {md:'14px', xs:'12px'},
                      fontWeight: 600,
                      lineHeight: '16px',
                      color: '#102691',
                      border: 'none',
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    Print/Save Report
                    <ArrowForwardIcon sx={{ marginLeft: "5px" }} />
                  </Button>
                  {/* <AbsButton
                    title=""
                    type="submit"
                    onClick={generateReport}
                  /> */}
                  {
                    isCreatingNew ? ( <Button
                      sx={{
                        border: "1.5px solid transparent",
                        background:
                          "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #B6FFE5 0%, #4EEEB3 100%) border-box",
                        "& .MuiButton-label": {
                          background:
                            "linear-gradient(90deg, #B6FFE5 0%, #4EEEB3 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                          fontSize: {md:'16px', xs:'12px'},
                        },
                        "& .MuiSvgIcon-root": {
                          background:
                            "linear-gradient(90deg, #B6FFE5 0%, #4EEEB3 100%)",
                          WebkitBackgroundClip: "text",
                          WebkitTextFillColor: "transparent",
                        },
                        color: "#4EEEB3",
                        padding: {md:"8px 50px"},
                        "&:hover": {
                          background:
                            "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #4EEEB3 0%, #B6FFE5 100%) border-box",
                        },
                      }}
                      onClick={handleOpenNewScoreModal}
                    >
                      Create new score 
                      <ArrowForwardIcon sx={{ marginLeft: "5px" }} />
                    </Button>): ( <Button
                    sx={{
                      border: "1.5px solid transparent",
                      background:
                        "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #B6FFE5 0%, #4EEEB3 100%) border-box",
                      "& .MuiButton-label": {
                        background:
                          "linear-gradient(90deg, #B6FFE5 0%, #4EEEB3 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                        fontSize: {md:'16px', xs:'12px'},
                      },
                      "& .MuiSvgIcon-root": {
                        background:
                          "linear-gradient(90deg, #B6FFE5 0%, #4EEEB3 100%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "transparent",
                      },
                      color: "#4EEEB3",
                      padding: {md:"8px 50px"},
                      "&:hover": {
                        background:
                          "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #4EEEB3 0%, #B6FFE5 100%) border-box",
                      },
                    }}
                    onClick={handleOpenSaveModal}
                  >
                    Save the score
                    <ArrowForwardIcon sx={{ marginLeft: "5px" }} />
                  </Button>)
                  }
                 
                 
                </Box>
              </Box>

              <Box
                sx={{
                  borderRadius: "10px",
                  border: "2px solid transparent",
                  height: { md: "390px", xs: "400px" },
                  marginLeft: { md: "50px" },
                  background:
                    "linear-gradient(#000, #000) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box",
                  marginTop: {md:"150px", xs:"30px"},
                }}
              >
                <Typography
                  sx={{
                    color: "#FFF",
                    fontSize: { md: "22px", xs: "16px" },
                    fontWeight: 500,
                    paddingTop: "20px",
                    px: "20px",
                  }}
                >
                  See How Your Score Has Evolved
                </Typography>
                <Typography
                  sx={{
                    color: "#B7B7B7",
                    fontSize: { md: "14px", xs: "10px" },
                    fontWeight: 400,
                    marginTop: "10px",
                    px: "20px",
                  }}
                >
                  Explore a detailed timeline of your financial performance and
                  see how your score has changed over time.
                </Typography>
                <Button
                  onClick={() => navigate("/score-graph")}
                  sx={{
                    display: { md: "block", xs: "none" },
                    marginTop: "20px",
                    marginLeft: "20px",
                    background:
                      "linear-gradient(266.57deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)",
                    borderRadius: "10px",
                    color: "#102691",
                    fontWeight: 600,
                    fontSize: "14px",
                    padding: "8px 16px",
                    textTransform: "none", // Prevents uppercase transformation
                    "&:hover": {
                      background:
                        "linear-gradient(266.57deg, #4EEEB3 -4.01%, #B6FFE5 89.75%)", // Optional: reverse gradient on hover
                    },
                  }}
                >
                  View Your Score Graph
                </Button>

                <div style={{ width: "100%", height: "200px" }}>
                  <Line data={chartData} options={chartOptions} />
                </div>
                <Button
                  sx={{
                    display: { md: "none", xs: "block" },
                    marginTop: "20px",
                    marginLeft: "80px",
                    background:
                      "linear-gradient(266.57deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)",
                    borderRadius: "10px",
                    color: "#102691",
                    fontWeight: 600,
                    fontSize: "14px",
                    padding: "8px 16px",
                    textTransform: "none", // Prevents uppercase transformation
                    "&:hover": {
                      background:
                        "linear-gradient(266.57deg, #4EEEB3 -4.01%, #B6FFE5 89.75%)", // Optional: reverse gradient on hover
                    },
                  }}
                >
                  View Your Score Graph
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* {!showInputForm && (
          <Button
            onClick={() => addNewScore()}
            sx={{
              display: { md: "block", xs: "none" },
              marginTop: "20px",
              marginLeft: "20px",
              background:
                "linear-gradient(266.57deg, #B6FFE5 -4.01%, #4EEEB3 89.75%)",
              borderRadius: "10px",
              color: "#102691",
              fontWeight: 600,
              fontSize: "14px",
              padding: "8px 16px",
              textTransform: "none", // Prevents uppercase transformation
              "&:hover": {
                background:
                  "linear-gradient(266.57deg, #4EEEB3 -4.01%, #B6FFE5 89.75%)", // Optional: reverse gradient on hover
              },
            }}
          >
            Add new score
          </Button>
        )} */}
        <Box sx={{ paddingLeft: {md:"30px", xs:"0px"}, paddingRight: {md:"30px", xs:"0px"} }}>
          <Box
            sx={{
              marginTop: "60px",
              width: "100%",
              height: "1.5px",
              background:
                "linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)",
            }}
          />
          <Box sx={{ display:{md: "flex", xs: "none"}, justifyContent: "center", gap: "20px" }}>
            <img
              src={process.env.PUBLIC_URL + "/left.svg"}
              alt="Left arrow"
              style={{ width: "200px", height: "220px" }}
            />
            <Box display="flex" flexDirection="column" alignItems="center">
              <Typography
                sx={{
                  color: "#D9D9D9",
                  fontSize: "18px",
                  fontWeight: 500,
                  marginTop: "30px",
                }}
              >
                If you like PFScores:
              </Typography>
              <Box sx={{ display: "flex", marginTop: "20px" }}>
                <Button
                  variant="outlined"
                  size="large"
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpfscores.com%2F"
                  target="_blank"
                  sx={{
                    lineHeight: 1,
                    marginRight: "15px",
                    marginTop: "15px",
                    borderRadius: "5px",
                    border: "1px solid transparent",
                    fontSize: "14px",
                    color: "#2C70FC",
                    background:
                      "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%) border-box",
                  }}
                >
                  Please Tell a friend
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  href="/feedback"
                  sx={{
                    lineHeight: 1,
                    marginRight: { md: "15px", xs: "0px" },
                    marginTop: "15px",
                    borderRadius: "5px",
                    border: "1px solid transparent",
                    fontSize: "14px",
                    color: "#2C70FC",
                    background:
                      "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%) border-box",
                  }}
                >
                  Provide Feedback
                </Button>
              </Box>
            </Box>
            <img
              src={process.env.PUBLIC_URL + "/right.svg"}
              alt="Right arrow"
              style={{ width: "200px", height: "220px" }}
            />
          </Box>
          <Box sx={{ display:{md: "none"}, justifyContent: "center", gap: "20px" }}>
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", gap:2}}>
            <img
              src={process.env.PUBLIC_URL + "/left.svg"}
              alt="Left arrow"
              style={{ width: "80px", height: "80px" }}
            />
            <Typography sx={{color:"white", fontSize:"16px", fontWeight:500}}>
            If you like PFScores:
            </Typography>
            <img
              src={process.env.PUBLIC_URL + "/right.svg"}
              alt="Right arrow"
              style={{ width: "80px", height: "80px" }}
            />
            
            </Box>
            <Box sx={{ display: "flex", marginTop: "10px" }}>
                <Button
                  variant="outlined"
                  size="large"
                  href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fpfscores.com%2F"
                  target="_blank"
                  sx={{
                    lineHeight: 1,
                    marginRight: "15px",
                    marginTop: "15px",
                    borderRadius: "5px",
                    border: "1px solid transparent",
                    fontSize: "14px",
                    color: "#2C70FC",
                    background:
                      "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%) border-box",
                  }}
                >
                  Please Tell a friend
                </Button>
                <Button
                  variant="outlined"
                  size="large"
                  href="/feedback"
                  sx={{
                    lineHeight: 1,
                    marginRight: { md: "15px", xs: "0px" },
                    marginTop: "15px",
                    borderRadius: "5px",
                    border: "1px solid transparent",
                    fontSize: "14px",
                    color: "#2C70FC",
                    background:
                      "linear-gradient(#000, #000) padding-box, linear-gradient(90deg, #2C70FC 0%, #5BA8F6 100%) border-box",
                  }}
                >
                  Provide Feedback
                </Button>
              </Box>
            

          </Box>
          <Box
            sx={{
              my: "20px",
              width: "100%",
              height: "1.5px",
              background:
                "linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)",
            }}
          />
        </Box>
        <Typography
          sx={{
            color: "#FFF",
            fontSize: "22px",
            fontWeight: 400,
            textAlign: "center",
          }}
        >
          How your score was calculated
        </Typography>

        <IndividualScoreDisplayComponent
          score={props.debtScore}
          maxScore={350}
          title="Debt Management"
          description={
            "Debt management is how well you are managing your short-term and long-term debt. Short-term debt refers to payments you need to make over the course of the year. Your debt management score is " +
            props.debtScore +
            " against a maximum possible score of 350."
          }
        />
        <IndividualScoreDisplayComponent
          score={props.savingsScore}
          maxScore={200}
          title="Savings Discipline Score"
          description={
            "Savings discipline represents how well you are saving to achieve your future financial goals such as retirement, purchasing a home, saving for collage, etc. Your savings discipline score is " +
            props.savingsScore +
            " against a maximum possible score of 200."
          }
        />
        <IndividualScoreDisplayComponent
          score={props.riskScore}
          maxScore={200}
          title="Risk Management Score"
          description={
            "Risk management is how well you are protecting and your family against risks by having insurance or eliminating risk. Your risk management score is " +
            props.riskScore +
            " against a maximum possible score of 200."
          }
        />
        <Box></Box>
      </Box>
      <SaveScoreModal 
        open={openSaveModal}
        handleClose={handleCloseSaveModal}
        type="save"
      />
      <SaveScoreModal 
        open={openNewScoreModal}
        handleClose={handleCloseNewScoreModal}
        type="new"
      />
    </Container>
  );
}

export default ScoreDisplayComponent;
