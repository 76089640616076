/**
 * Custom hook for handling user account deletion functionality.
 * Manages the process of deleting a user account with email/password verification,
 * handles loading states, error messages, and updates the auth context upon successful deletion.
 */

import { useState } from "react"; //useContext
import { useAuthContext } from "./useAuthContext";
import { deleteAccount } from '../apiServices';
// import { useNavigate } from "react-router-dom";

export const useDeleteAccount= () => {
    // const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    const deleteaccount = async ( email, password) => {
        setIsLoading(true);
        setError(null);
        const response = await deleteAccount( email, password )
        
        console.log("response is:", response);
        let isSuccess = true;
        if (response.deleteMyProfile.http_status!==200) {
            isSuccess = false;
            setError(response.deleteMyProfile.message);
        } else {
            isSuccess = true;
            setError(response.deleteMyProfile.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.deleteMyProfile.message);
        } else {
            // update authcontext
            dispatch({type: 'REGISTER', payload: response.deleteMyProfile.data})
            setIsLoading(false)
            // console.log("SignUp Done!!!");
            // navigate('/activation-notification')
    
        }
        return isSuccess;
    }

    return {deleteaccount, isLoading, error}
}