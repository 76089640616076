/**
 * Custom hook for managing cash flow data operations.
 * Provides functionality to load and save cash management details including
 * income sources (salary, rental, etc.), expenses (mortgage, loans, taxes),
 * and other financial commitments. Handles API interactions and local storage
 * for cash management data.
 */
import { useContext, useState } from "react";
import { useAuthContext } from "./useAuthContext";
import { getMyCashManagement, addOrUpdateMyCashManagement } from '../apiServices/pfscoreForms/cashManagement';

import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";

export const useCashManagement= () => {
    const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const {dispatch} = useAuthContext()

    const loadCashManagement = async () => {
        setIsLoading(true);
        setError(null);
        const response = await getMyCashManagement()
        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("loadCashManagement Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('CashManagement', response.getMyCashManagement.data);
            setIsLoading(false);
            console.log("Feteched Initial Data for CashManagement!!!");
        }
    }

    const saveCashManagement = async (updateData) => {
        setIsLoading(true);
        setError(null);
        // salaryBonusPretaxPeriod, salaryBonusPretaxAmount, rentalIncomePeriodPeriod, rentalIncomeAmount, anyOtherIncomeAmount, anyOtherIncomeDescription, homeMortgagePaymentPeriod, homeMortgagePaymentAmount, propertyTaxesAnnual, loanPaymentCarsOtherMajorItemsPeriod, loanPaymentCarsOtherMajorItemsAmount, otherExpensesPeriod, otherExpensesAmount, makeTimelyPaymentsOnDebt, lifeExpensesPeriod, lifeExpensesAmount, healthInsurancePaymentPeriod, healthInsurancePaymentAmount, travelEntertainmentPeriod, travelEntertainmentAmount, estimatedTaxesAnnual, anyOtherMonthlyPaymentsDesc, anyOtherMonthlyPaymentsAmount
        const response = await addOrUpdateMyCashManagement(...updateData);        
        console.log("response is:", response);
        let isSuccess = true;
        if ("errors" in response && response.http_status!==200) {
            isSuccess = false;
        } else {
            isSuccess = true;
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response['errors'])
            console.log("saveCashManagement Errors: ", response['errors'])
        } else {
            // save data to localStorage
            localStorage.setItem('CashManagement', response.addOrUpdateMyCashManagement.data);
            // populate form with initial values
            // dispatch({type: 'REGISTER', payload: response.getMyPersonalDetails})
            setIsLoading(false);
            console.log("saved CashManagement details!!!");
        }
        return isSuccess
    }

    return {loadCashManagement, saveCashManagement, isLoading, error}
}