import { pdf } from '@react-pdf/renderer';
import PdfReport from '../components/pdfReport/PdfReport';

export const generatePdf = async (userData, scores) => {
  const blob = await pdf(PdfReport({ userData, scores })).toBlob();
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = 'financial-health-report.pdf';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(url);
}; 