import gqlWebClient from "../../apolloClient";
import { gql } from "@apollo/client";

const COMPLETE_MY_SCORE = gql`
  mutation CompleteMyScore($data: CompleteMyScoreInput!) {
    completeMyScore(data: $data) {
      code
      message
      http_status
      errors
    }
  }
`;

const CREATE_NEW_SCORE = gql`
  mutation CreateNewScore($data: CreateNewScoreInput!) {
    createNewScore(data: $data) {
      code
      message
      http_status
      errors
    }
  }
`;

const GET_LATEST_SCORE = gql`
  query GetLatestScore {
    getLatestScore {
      code
      message
      http_status
      data {
        personalDetails {
          plan_to_save_for_college
          own_home
          own_additional_properties
          own_business
          plan_for_major_purchase
          annual_household_income
          value_of_investments
          know_credit_score
          have_college_saving_plan
          know_value_of_home
        }
        netWorth {
          market_value_primary_home
          market_value_cars_major_items
          market_value_personal_valuables
          any_other_assets_description
          any_other_assets_amount
          
          total_savings
          cash_savings
          current_value_retirement_accounts
          value_all_brokerage_accounts
          
          is_mortgage_primary_home
          mortgage_interest_rate
          mortgage_years_left
          mortgage_type
          mortgage_years_total
          mortgage_balance_primary_home
          loan_balance_cars_other_major_items
          credit_card_debt_loan_balance
          any_other_loans_description
          is_other_loan
          any_other_loans_amount
        }
        cashManagement {
          salary_bonus_pretax_period
          salary_bonus_pretax_amount
          rental_income_period_period
          rental_income_amount
          any_other_income_period
          any_other_income_amount
          any_other_income_description
          
          home_mortgage_payment_period
          home_mortgage_payment_amount
          property_taxes_annual
          other_expenses_amount
          
          life_expenses_period
          life_expenses_amount
          health_insurance_payment_period
          health_insurance_payment_amount
          travel_entertainment_period
          travel_entertainment_amount
          
          estimated_taxes_annual
          any_other_monthly_payments_desc
          any_other_monthly_payments_amount
        }
        retirement {
          number_of_years_left_to_retire
          expected_duration_of_retirement
          housing_expenses_period
          housing_expenses_amount
          healthcare_expenses
          life_expenses
          te_expenses
          other_expenses
          expected_mortgage_at_retirement
          other_expected_loans_at_retirement
          legacy_you_want_to_leave
          all_retirement_accounts_value
          average_monthly_contribution_by_employer
          monthly_contribution
          estimated_monthly_pension_benefit
          estimated_monthly_soc_sec_benefit
          expected_avg_inflation_rate
          expected_avg_annual_return_retirement_balance
        }
        majorPurchases {
          name_of_purchase
          num_years_left_for_purchase
          amount_needed_as_full_down_payment
          amount_saved_so_far_for_purchase
          current_monthly_savings_for_purchase
          exp_avg_annual_return_on_savings
        }
        dependentProtection {
          other_one_time_expenses
          exp_num_years_support_dependents
          exp_avg_annual_long_term_investment
          exp_annual_inflation_rate
          self_purchased_life_insurance_type
          self_purchased_life_insurance_amount
          employer_provided_life_insurance_amount
          have_homeowner_insurance
          have_health_insurance
          have_long_term_disability_ins
          have_short_term_disability_ins
          have_umbrella_policy
          have_power_of_attorney
          have_revocable_trust
          have_long_term_care_insurance
          have_will
          have_living_will
        }
        education {
          one_year_college_cost
          avg_annual_tuition_inflation
          children {
            name_of_child
            age_of_child
            percent_of_cost_for_child
            amount_saved_so_far
            current_monthly_savings_for_child
            expected_annual_return_on_savings
          }
        }
        score
        debtScore
        savingsScore
        riskScore
        progress
        name
        description
        isMainScore
        addedOn
      }
      errors
    }
  }
`;

const GET_USER_PFI_SCORES = gql`
  query GetUserPfiScores {
    getUserPfiScores {
      code
      message
      http_status
      data {
        score
        debtScore
        savingsScore
        riskScore
        name
        description
      }
      errors
    }
  }
`;

export const completeScore = async function (name, description, isMainScore) {
  console.log('Inside completeScore()');
  console.log(`name: ${name}`);
  console.log(`description: ${description}`);
  console.log(`isMainScore: ${isMainScore}`);

  const { data } = await gqlWebClient.mutate({
    mutation: COMPLETE_MY_SCORE,
    variables: {
      data: {
        name: name,
        description: description,
        isMainScore: isMainScore
      }
    }
  });

  console.log("completeScore API Response is: ", data);
  return data;
};

export const createNewScore = async function (name, description, isMainScore) {
  console.log('Inside createNewScore()');
  console.log(`name: ${name}`);
  console.log(`description: ${description}`);

  const { data } = await gqlWebClient.mutate({
    mutation: CREATE_NEW_SCORE,
    variables: {
      data: {
        name: name,
        description: description,
        isMainScore: isMainScore
      }
    }
  });

  console.log("createNewScore API Response is: ", data);
  return data;
};

export const getLatestScore = async function () {
  const { data } = await gqlWebClient.query({
    query: GET_LATEST_SCORE
  });
  console.log("getLatestScore API Response is: ", data);
  return data;
};

export const getUserPfiScores = async function() {
  const { data } = await gqlWebClient.query({
    query: GET_USER_PFI_SCORES
  });
  console.log("getUserPfiScores API Response is: ", data);
  return data;
};