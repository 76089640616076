/**
 * Custom hook for handling user registration and profile updates.
 * Provides functions for new user signup and updating existing user profiles,
 * along with loading and error states. Integrates with authentication context
 * and local storage for user session management.
 */

import { useState } from "react"; //useContext
import { useAuthContext } from "./useAuthContext";
import { register, updateUser } from '../apiServices';
// import { useNavigate } from "react-router-dom";

export const useSignup = () => {
    // const navigate = useNavigate();
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const { dispatch } = useAuthContext()

    const signup = async (first_name, last_name, email, password, confirmPassword, zip, birth_year, gender, marital_status, education_level, phone, utm_source, utm_medium, utm_campaign, utm_keyword, referrer, state) => {
        setIsLoading(true);
        setError(null);
        const response = await register(first_name, last_name, email, password, confirmPassword, zip, birth_year, gender, marital_status, education_level, phone, utm_source, utm_medium, utm_campaign, utm_keyword, referrer, state)

        console.log("response is:", response);
        let isSuccess = true;
        if (response.createUser.http_status !== 200) {
            isSuccess = false;
            setError(response.createUser.message);
        } else {
            isSuccess = true;
            setError(response.createUser.message);
        }

        if (!isSuccess) {
            setIsLoading(false);
            setError(response.createUser.message);
        } else {
            // do not save the token ask user to login again
            localStorage.setItem('token', response.createUser.data.token);
            localStorage.setItem('user', JSON.stringify(response.createUser.data.user));
            // update authcontext
            dispatch({ type: 'REGISTER', payload: response.createUser.data })
            setIsLoading(false)
            // console.log("SignUp Done!!!");
            // navigate('/activation-notification')

        }
        return isSuccess;
    }
    const updateProfile = async (first_name, last_name, phone, zip, birth_year, gender, marital_status, education_level) => {
        setIsLoading(true);
        setError(null);

        try {
            const response = await updateUser(first_name, last_name, phone, zip, birth_year, gender, marital_status, education_level);

            console.log("Update profile response:", response);

            if (response.updateUser.http_status !== 200) {
                setError(response.updateUser.message);
                setIsLoading(false);
                return false;
            } else {
                // Update local storage
                localStorage.setItem('token', response.updateUser.data.token);
                localStorage.setItem('user', JSON.stringify(response.updateUser.data.user));

                // Update auth context
                dispatch({
                    type: 'UPDATE_USER',
                    payload: {
                        user: response.updateUser.data.user,
                        token: response.updateUser.data.token
                    }
                });

                setIsLoading(false);
                return true;
            }
        } catch (err) {
            setError('An error occurred while updating the profile');
            setIsLoading(false);
            return false;
        }
    }

    return { signup, updateProfile, isLoading, error }
}