import React from "react";
import "./PersonalDetails.css";
import { Container, Box, Typography } from "@mui/material";
import RadioComponent from "../radioComponent/RadioComponent";
import DropDownComponent from "../dropdownComponent/DropDownComponent";
import AbsButton from "../absButton/AbsButton";
import NumInput from "../numInput/NumInput";
// import RangeSlider from "../rangeSlider/RangeSlider";
import { useState } from "react";
import { PFIFormConstants } from "../../constants/PFIFormConstants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import { usePersonalDetails } from "../../hooks/usePersonalDetails";
import { getMyPersonalDetails } from "../../apiServices/pfscoreForms/personalDetails";
// import { usePersonalDetails } from '../../../hooks/usePersonalDetails';
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import allActions from "../../store/actions";
import { checkInitiatedScore } from "../../apiServices/pfscoreForms/financialHealth";
import { useNavigate } from "react-router-dom";

/**
 * PersonalDetails component handles the collection of user's personal and financial information
 * including college savings plans, home ownership, business ownership, household income,
 * investments, and other financial indicators. This information is used to generate the user's
 * personal financial score (PFScore).
 */
const PersonalDetails = ({ next }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const { savePersonalDetails, isLoading, error } = usePersonalDetails();
  const CURR_CMP = "PersonalDetails";
  const datafromreducer = useSelector((state) => state.PersonalDetailsReducer);
  const funcitonsformreducer = useDispatch();

  const capitalizeUtil = function (word) {
    return word[0].toUpperCase() + word.slice(1).toLowerCase();
  };
  const navigate = useNavigate();

  useEffect(() => {
    const fetchScoreStatus = async () => {
      try {
        const response = await checkInitiatedScore();
        if (!response?.checkInitiatedScore?.anyInitiatedScore) {
          navigate("/financial-health");
        }
      } catch (err) {
        console.log("Error while checking initiated score: ", err);
      }
    };

    fetchScoreStatus();
  }, []);

  const _handleSubmit = async (e) => {
    e.preventDefault();

    const updateDta = [
      datafromreducer.college === "Yes" ? true : false,
      datafromreducer.ownHome === "Yes" ? true : false,
      datafromreducer.addProperties === "Yes" ? true : false,
      datafromreducer.business === "Yes" ? true : false,
      datafromreducer.majorPurchases === "Yes" ? true : false,
      parseInt(datafromreducer.houseHold),
      parseInt(datafromreducer.investment),
      datafromreducer.knowCreditScore === "Yes" ? true : false,
      datafromreducer.haveCollegeSavingPlan === "Yes" ? true : false,
      datafromreducer.knowValueOfHome === "Yes" ? true : false,
    ];

    console.log("Update data is:");
    console.log(updateDta);

    setIsSaving(true);
    const response = await savePersonalDetails(updateDta);
    setIsSaving(false);

    if (response) {
      console.log("Save personal details Response: ", response);
      next();
    } else {
      console.log("Save personal details: Errors - Alert");
    }
  };

  const getCurrentVal = (variable) => {
    // console.log(`${CURR_CMP}:: Getting current value for: ${variable}`);
    switch (variable) {
      case "COLLEGE":
        return datafromreducer.college;
      case "OWN_HOME":
        return datafromreducer.ownHome;
      case "ADD_PROPERTIES":
        return datafromreducer.addProperties;
      case "BUSINESS":
        return datafromreducer.business;
      case "MAJOR_PURCHASES":
        return datafromreducer.majorPurchases;
      case "HOUSEHOLD_INCOME":
        return datafromreducer.houseHold;
      case "INVESTMENT":
        return datafromreducer.investment;
      case "KNOW_CREDIT_SCORE":
        return datafromreducer.knowCreditScore;
      case "HAVE_COLLEGE_SAVING_PLAN":
        return datafromreducer.haveCollegeSavingPlan;
      case "KNOW_VALUE_OF_HOME":
        return datafromreducer.knowValueOfHome;
      default:
        return 0;
    }
  };

  const handlePlusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(true, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  const handleMinusClick = (variable, step = 100) => {
    const currVal = getCurrentVal(variable);
    const finalVal = calculateNumValue(false, currVal, step);
    funcitonsformreducer({
      type: variable,
      payload: finalVal,
    });
  };

  // Function is called everytime increment/decrement button is clicked
  const calculateNumValue = (order, currentVal = 0, step = 1) => {
    const minVal = 0;
    const maxVal = 1000000000000;
    const currVal = parseInt(currentVal);
    if (order) {
      // increment the value by step
      const incrVal = currVal + step;
      if (incrVal > maxVal) {
        return maxVal;
      } else {
        return incrVal;
      }
    } else {
      // decrement the value by step
      const decrVal = currVal - step;
      if (decrVal < minVal) {
        return minVal;
      } else {
        return decrVal;
      }
    }
  };

  const inputOnchange = (value, type) => {
    funcitonsformreducer({
      type: type,
      payload: value,
    });
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      try {
        const response = await getMyPersonalDetails();
        const data = await response.getMyPersonalDetails;
        setIsLoaded(true);
        let updateData = {
          college: PFIFormConstants.personal_details.details.college.default,
          ownHome: PFIFormConstants.personal_details.details.own_home.default,
          addProperties:
            PFIFormConstants.personal_details.details.add_properties.default,
          business: PFIFormConstants.personal_details.details.business.default,
          majorPurchases:
            PFIFormConstants.personal_details.details.major_pur.default,
          houseHold: PFIFormConstants.personal_details.details.income.default,
          investment:
            PFIFormConstants.personal_details.details.investment.default,
          knowCreditScore: PFIFormConstants.personal_details.details.know_credit_score.default,
          haveCollegeSavingPlan: PFIFormConstants.personal_details.details.have_college_saving_plan.default,
          knowValueOfHome: PFIFormConstants.personal_details.details.know_value_of_home.default,
        };

        if (data.http_status === 200) {
          // Assign default Values
          updateData = {
            college: data.data.plan_to_save_for_college === true ? "Yes" : "No",
            ownHome: data.data.own_home === true ? "Yes" : "No",
            addProperties:
              data.data.own_additional_properties === true ? "Yes" : "No",
            business: data.data.own_business === true ? "Yes" : "No",
            majorPurchases:
              data.data.plan_for_major_purchase === true ? "Yes" : "No",
            houseHold: data.data.annual_household_income,
            investment: data.data.value_of_investments,
            knowCreditScore: data.data.know_credit_score === true ? "Yes" : "No",
            haveCollegeSavingPlan: data.data.have_college_saving_plan === true ? "Yes" : "No",
            knowValueOfHome: data.data.know_value_of_home === true ? "Yes" : "No",
          };
        }
        console.log(`Personal Details Initial update Data: ${updateData}`);
        console.log(updateData);

        funcitonsformreducer({
          type: "ALL_PD",
          payload: updateData,
        });

        if (data.score && data.score.overAllScore) {
          funcitonsformreducer(
            allActions.scoreActions.UpdateScore({
              overAllScore: data.score.overAllScore,
              debtScore: data.score.debtScore,
              riskScore: data.score.riskScore,
              savingsScore: data.score.savingsScore,
              debugInfo: JSON.stringify(data.score),
            })
          );
        }
      } catch (err) {
        console.log("Initial Load Error:", err);
        if (err && err.errors) {
          setLoadingError(err.errors[0].message);
        }
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="personal_detail_page">
        <Container maxWidth="lg" className="personal_det">
          <Box className="row personal_row_section">
            <DetailsFormHeading
              title_text={"Personal Information"}
              subtitle_text={
                "Why? Click on our Privacy Policy below. If you elect not to continue, please click on the Feedback link below. We will never share your contact info with any 3rd party."
              }
            />
            {/* <Box className="personal_column_">
              <h4 className="title_text">Personal Information</h4>
            <Box className="personal_detail_Sect_"></Box>
            <h6 className="subtitle_text">PFScores needs this information to generate a score.</h6>
            <p>PFScores needs this information to generate a score.</p>
              <h4>Personal Financial Scores &#174; (PFScores)</h4>
              <Box className="audio_section">
                <audio controls>
                  <source src={PFIFormConstants.personal_details.audio_ogg_path} type="audio/ogg" />
                  <source src={PFIFormConstants.personal_details.audio_mp3_path} type="audio/mpeg" />
                </audio>
              </Box>
            </Box> */}
          </Box>
          {/* <Box className="row">
            <Box className="personal_detail_Sect_">
              <h6>Personal Details</h6>
            </Box>
          </Box> */}
          {isLoaded && (
            <Box className="personal_information ">
              <form>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Plan to save for college education?
                    </Typography>
                  </Box>
                  <Box>
                    {/* <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue="Yes"
                    handleRadioOption={(e) => setCollege(e.target.value)}
                  /> */}
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("COLLEGE")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "COLLEGE")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">Do you own a home?</Typography>
                  </Box>
                  <Box>
                    {/* <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    value2="No"
                    defaultValue="No"
                    handleRadioOption={(e) => setOwnHome(e.target.value)}
                  /> */}
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("OWN_HOME")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "OWN_HOME")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Do you own additional properties?
                    </Typography>
                  </Box>
                  <Box>
                    {/* <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    handleRadioOption={(e) => setAddProperties(e.target.value)}
                    value2="No"
                    defaultValue="No"
                  /> */}
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("ADD_PROPERTIES")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "ADD_PROPERTIES")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Do you own a business?
                    </Typography>
                  </Box>
                  <Box>
                    {/* <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    handleRadioOption={(e) => setBusiness(e.target.value)}
                    value2="No"
                    defaultValue="No"
                  /> */}
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("BUSINESS")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "BUSINESS")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Plan to save for any major purchases?
                    </Typography>
                  </Box>
                  <Box>
                    {/* <RadioComponent
                    label1="Yes"
                    label2="No"
                    value1="Yes"
                    handleRadioOption={(e) => setMajorPur(e.target.value)}
                    value2="No"
                    defaultValue="No"
                  /> */}
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("MAJOR_PURCHASES")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "MAJOR_PURCHASES")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Approximate annual household income.
                    </Typography>
                  </Box>
                  <Box className="drop_selection_wrapper">
                    <NumInput
                      value={getCurrentVal("HOUSEHOLD_INCOME")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "HOUSEHOLD_INCOME")
                      }
                      onPlusClick={(e) => handlePlusClick("HOUSEHOLD_INCOME")}
                      onMinusClick={(e) => handleMinusClick("HOUSEHOLD_INCOME")}
                      placeholder="$ value of annual household income"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">
                      Approximate value of investments.
                    </Typography>
                  </Box>
                  <Box className="drop_selection_wrapper">
                    <NumInput
                      value={getCurrentVal("INVESTMENT")}
                      onChange={(e) =>
                        inputOnchange(e.target.value, "INVESTMENT")
                      }
                      onPlusClick={(e) => handlePlusClick("INVESTMENT")}
                      onMinusClick={(e) => handleMinusClick("INVESTMENT")}
                      placeholder="$ value of investments"
                      displayCurrency={true}
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">Do you know your credit score?</Typography>
                  </Box>
                  <Box>
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("KNOW_CREDIT_SCORE")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "KNOW_CREDIT_SCORE")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">Do you have a 569 college savings plan?</Typography>
                  </Box>
                  <Box>
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("HAVE_COLLEGE_SAVING_PLAN")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "HAVE_COLLEGE_SAVING_PLAN")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    <Typography variant="body1">Do you know the value of your home?</Typography>
                  </Box>
                  <Box>
                    <RadioComponent
                      label1="Yes"
                      label2="No"
                      value1="Yes"
                      value2="No"
                      defaultValue={getCurrentVal("KNOW_VALUE_OF_HOME")}
                      handleRadioOption={(e) =>
                        inputOnchange(e.target.value, "KNOW_VALUE_OF_HOME")
                      }
                    />
                  </Box>
                </Box>
                <Box className="detail_sectionss2">
                  <Box className="info_left_sec2">
                    {/* <p>Approx value of<br/> investment</p> */}
                  </Box>
                  <Box
                    className="drop_selection_wrapper"
                    sx={{ marginTop: "80px" }}
                  >
                    <AbsButton
                      title="Next Page"
                      type="submit"
                      onClick={_handleSubmit}
                    />
                  </Box>
                </Box>

                {isSaving && (
                  <Typography variant="body1">Saving....</Typography>
                )}
              </form>
            </Box>
          )}
          {!isLoaded && <Typography variant="body1">Loading...</Typography>}

          {error && <Box>Error: {error}</Box>}
          {loadingError && <Box>Error: {loadingError}</Box>}
        </Container>
      </section>
    </>
  );
};

export default PersonalDetails;
