export const getBirthYearOptions = (minAge = 1, maxAge = 125) => {
  if (maxAge > 125) {
    maxAge = 125;
  }
  if (minAge < 1) {
    minAge = 1;
  }

  const today = new Date();
  const currentYear = parseInt(today.getFullYear());
  let yearOptions = [];
  for (let index = minAge; index < maxAge; index++) {
    // console.log(`Index: ${index} - minAge: ${minAge} - maxAge: ${maxAge} - Adding Year: ${currentYear - index}`); 
    yearOptions.push(currentYear - index);
  }
  return yearOptions;
};

// console.log(getBirthYearOptions(0, 100));

export const getDefaultBirthYear = (defaultAge = 40) => {
  if (defaultAge > 125) {
    defaultAge = 125;
  }
  if (defaultAge < 1) {
    defaultAge = 1;
  }
  const today = new Date();
  const currentYear = parseInt(today.getFullYear());
  return (currentYear - defaultAge);
};

// console.log(getDefaultBirthYear(33));

export const getAllStates = () => {
  return [
    { id: 'AL', name: 'Alabama' },
    { id: 'AK', name: 'Alaska' },
    { id: 'AZ', name: 'Arizona' },
    { id: 'AR', name: 'Arkansas' },
    { id: 'CA', name: 'California' },
    { id: 'CO', name: 'Colorado' },
    { id: 'CT', name: 'Connecticut' },
    { id: 'DE', name: 'Delaware' },
    { id: 'FL', name: 'Florida' },
    { id: 'GA', name: 'Georgia' },
    { id: 'HI', name: 'Hawaii' },
    { id: 'ID', name: 'Idaho' },
    { id: 'IL', name: 'Illinois' },
    { id: 'IN', name: 'Indiana' },
    { id: 'IA', name: 'Iowa' },
    { id: 'KS', name: 'Kansas' },
    { id: 'KY', name: 'Kentucky' },
    { id: 'LA', name: 'Louisiana' },
    { id: 'ME', name: 'Maine' },
    { id: 'MD', name: 'Maryland' },
    { id: 'MA', name: 'Massachusetts' },
    { id: 'MI', name: 'Michigan' },
    { id: 'MN', name: 'Minnesota' },
    { id: 'MS', name: 'Mississippi' },
    { id: 'MO', name: 'Missouri' },
    { id: 'MT', name: 'Montana' },
    { id: 'NE', name: 'Nebraska' },
    { id: 'NV', name: 'Nevada' },
    { id: 'NH', name: 'New Hampshire' },
    { id: 'NJ', name: 'New Jersey' },
    { id: 'NM', name: 'New Mexico' },
    { id: 'NY', name: 'New York' },
    { id: 'NC', name: 'North Carolina' },
    { id: 'ND', name: 'North Dakota' },
    { id: 'OH', name: 'Ohio' },
    { id: 'OK', name: 'Oklahoma' },
    { id: 'OR', name: 'Oregon' },
    { id: 'PA', name: 'Pennsylvania' },
    { id: 'RI', name: 'Rhode Island' },
    { id: 'SC', name: 'South Carolina' },
    { id: 'SD', name: 'South Dakota' },
    { id: 'TN', name: 'Tennessee' },
    { id: 'TX', name: 'Texas' },
    { id: 'UT', name: 'Utah' },
    { id: 'VT', name: 'Vermont' },
    { id: 'VA', name: 'Virginia' },
    { id: 'WA', name: 'Washington' },
    { id: 'WV', name: 'West Virginia' },
    { id: 'WI', name: 'Wisconsin' },
    { id: 'WY', name: 'Wyoming' }
  ];
};

export const AuthConstants = {
  "FirstNameRegex": /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/,
  "LastNameRegex": /^[a-zA-ZÀ-ÖÙ-öù-ÿĀ-žḀ-ỿ0-9\s\-\/.]+$/,
  "PhoneRegex": /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
  "SecQuestionOneOptions": [
    {
      "id": 1,
      "name": "What was the first car you drove?"
    },
    {
      "id": 2,
      "name": "Your favorite fruit?"
    },
    {
      "id": 3,
      "name": "The name of your high school?"
    },
    {
      "id": 4,
      "name": "What's your favorite color?"
    },
    {
      "id": 5,
      "name": "our favorite movie?"
    },
    {
      "id": 6,
      "name": "Your favorite sport?"
    },

  ],
  "SecQuestionTwoOptions": [
    {
      "id": 1,
      "name": "What was the first car you drove?"
    },
    {
      "id": 2,
      "name": "Your favorite fruit?"
    },
    {
      "id": 3,
      "name": "The name of your high school?"
    },
    {
      "id": 4,
      "name": "What's your favorite color?"
    },
    {
      "id": 5,
      "name": "our favorite movie?"
    },
    {
      "id": 6,
      "name": "Your favorite sport?"
    },

  ],
  "GenderOptions": [
    {
      "id": 1,
      "name": "Male"
    },
    {
      "id": 2,
      "name": "Female"
    },
    {
      "id": 3,
      "name": "Other"
    },
    {
      "id": 4,
      "name": "Other2"
    },
  ],
  "MaritalStatusOptions": [
    {
      "id": 1,
      "name": "Married"
    },
    {
      "id": 2,
      "name": "Single"
    },
    {
      "id": 3,
      "name": "Other"
    },
  ],
  "AgeOptions": [
    {
      "id": 1,
      "name": "18-23"
    },
    {
      "id": 2,
      "name": "24-29"
    },
    {
      "id": 3,
      "name": "30-35"
    },
    {
      "id": 4,
      "name": "36-69"
    },
    {
      "id": 5,
      "name": "70+"
    },
  ],

  "EducationLevelOptions": [
    {
      "id": 1,
      "name": "High School"
    },
    {
      "id": 2,
      "name": "College"
    },
    {
      "id": 3,
      "name": "Some College"
    },
    {
      "id": 4,
      "name": "Other"
    },
  ],
  "BirthYearOptions": {
    "getBirthYearOptions": getBirthYearOptions,
    "getDefaultBirthYear": getDefaultBirthYear,
    "getAllStates": getAllStates
  }

}


