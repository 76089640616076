import { useState, useEffect } from "react";
import ScoreHistory from "../../../components/scoreHistory/ScoreHistory";
import { getRanking } from "../../../apiServices/pfscoreForms/financialHealth";
import { Typography, CircularProgress, Box } from "@mui/material";
const ScoreHistoryPage = () => {
    const [ranking, setRanking] = useState("");
    const [categoryDistribution, setCategoryDistribution] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const fetchRankingData = async (filters = null) => {
        try {
            setIsLoading(true);
            const rankingData = await getRanking(filters);
            setRanking(rankingData.getPeerGroupScores.ranking);
            setCategoryDistribution(rankingData.getPeerGroupScores.categoryDistribution);
        } catch (error) {
            console.error("Error fetching ranking:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchRankingData();
    }, []);

    const handleFilterChange = (filters) => {
        fetchRankingData(filters);
    };

    if (isLoading) {
        return  <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        justifyContent="center" 
        minHeight="200px"
        gap={2}
      >
        <CircularProgress size={40} />
        <Typography variant="h6" color="text.secondary">
          Loading your financial health score...
        </Typography>
      </Box>;
    }

    return (
        <ScoreHistory 
            ranking={ranking} 
            categoryDestribution={categoryDistribution}
            onFilterChange={handleFilterChange}
        />
    );
};

export default ScoreHistoryPage;