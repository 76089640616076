/**
 * MajorPurchases Component
 * 
 * This component handles the major purchases section of the financial assessment form.
 * It allows users to:
 * - Add/remove multiple major purchase goals
 * - Input details for each purchase including:
 *   - Years until purchase
 *   - Required down payment amount
 *   - Current savings
 *   - Monthly savings contribution
 *   - Expected return rate
 * - Save purchase data to backend
 * - Navigate between form sections
 */
import React from "react";
import "../cashManagment/CashManagment.css";
import "./MajorPurchases.css";
import { Container, Box, Tooltip, Button, Typography } from "@mui/material";
// import PersonalFinancialIndex from "../personalFinancialIndex/PersonalFinancialIndex";
// import RangeSlider from "../rangeSlider/RangeSlider";
import PrevBUtton from "../prevButton/PrevBUtton";
import AbsButton from "../../components/absButton/AbsButton";
// import {PFIFormConstants as PFIFC} from "../../constants/PFIFormConstants"
// import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import NumInput from "../numInput/NumInput";

import { useState } from "react";
import { useEffect } from "react";
import { useMajorPurchases } from "../../hooks/useMajorPurchases";
import { getMyMajorPurchases } from "../../apiServices/pfscoreForms/majorPurchases";
import DetailsFormHeading from "../detailsFormHeading/DetailsFormHeading";
import { individualMpInitialState } from "../../store/reducer/MajorPurchasesReducer";
import allActions from "../../store/actions";

import { checkInitiatedScore } from "../../apiServices/pfscoreForms/financialHealth";
import { useNavigate } from "react-router-dom";

const MajorPurchases = ({ next, pre }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  const {
    addMajorPurchases,
    updateMajorPurchases,
    removeMajorPurchases,
    isLoading,
    error,
  } = useMajorPurchases();

  const navigate = useNavigate();

  useEffect(() => {
    const fetchScoreStatus = async () => {
      try {
        const response = await checkInitiatedScore();
        if (!response?.checkInitiatedScore?.anyInitiatedScore) {
          navigate("/financial-health");
        }
      } catch (err) {
        console.log("Error while checking initiated score: ", err);
      }
    };

    fetchScoreStatus();
  }, []);

  const CURR_CMP = "MajorPurchases";

  const datafromreducer = useSelector((state) => state.MajorPurchasesReducer);
  const funcitonsformreducer = useDispatch();

  const _handleNextPage = async (e) => {
    e.preventDefault();

    const allPurchaseItems = datafromreducer.smpPurchases;
    let updateData = [];
    let addData = [];

    for (let index = 0; index < allPurchaseItems.length; index++) {
      const purchaseItem = allPurchaseItems[index];
      if (purchaseItem.pId) {
        updateData.push([
          purchaseItem.pId.toString(),
          purchaseItem.pName.toString(),
          parseInt(purchaseItem.pYearsToPurchase),
          parseInt(purchaseItem.pAmountNeeded),
          parseInt(purchaseItem.pAmountSaved),
          parseInt(purchaseItem.pMonthlySaving),
          parseInt(purchaseItem.pAnnualReturn),
        ]);
      } else {
        addData.push([
          purchaseItem.pName.toString(),
          parseInt(purchaseItem.pYearsToPurchase),
          parseInt(purchaseItem.pAmountNeeded),
          parseInt(purchaseItem.pAmountSaved),
          parseInt(purchaseItem.pMonthlySaving),
          parseInt(purchaseItem.pAnnualReturn),
        ]);
      }
    }

    console.log("Update data is:");
    console.log(updateData);

    console.log("Add data is:");
    console.log(addData);

    setIsSaving(true);
    for (let index = 0; index < addData.length; index++) {
      const addResponse = await addMajorPurchases(addData[index]);
    }
    for (let index = 0; index < updateData.length; index++) {
      const updateResponse = await updateMajorPurchases(updateData[index]);
    }
    setIsSaving(false);
    next();
  };

  const _handleAddNew = async (e) => {
    e.preventDefault();
    console.log(e);
    console.log("inside _handleAddNew (MP)");
    let defaultData = individualMpInitialState;
    // defaultData.pName = Date.now().toString();

    setIsSaving(true);

    const addData = [
      defaultData.pName.toString(),
      parseInt(defaultData.pYearsToPurchase),
      parseInt(defaultData.pAmountNeeded),
      parseInt(defaultData.pAmountSaved),
      parseInt(defaultData.pMonthlySaving),
      parseInt(defaultData.pAnnualReturn),
    ];
    const addResponse = await addMajorPurchases(addData);
    console.log("addMajorPurchases item Response is: ");
    console.log(addResponse);

    if (addResponse && addResponse.id) {
      console.log("addMajorPurchases: API Success!!");
      defaultData.pId = addResponse.id;
      funcitonsformreducer({
        type: "SMP_ADD",
        payload: {
          data: defaultData,
        },
      });
      funcitonsformreducer({
        type: "SMP_NUMBER",
        payload: {
          data: datafromreducer.smpNumber + 1,
        },
      });
    } else {
      // add operation failed - show error message to ui.
      console.log("addMajorPurchases: API Failed!!");
    }
  };

  const _handleRemove = async (e, itemIndex) => {
    e.preventDefault();

    if (datafromreducer.smpPurchases.length === 0) {
      return;
    }

    if (itemIndex === undefined) {
      itemIndex = datafromreducer.smpPurchases.length - 1;
    }
    // console.log(e);
    console.log(`itemIndex is: ${itemIndex}`);
    const itemToRemove = datafromreducer.smpPurchases[itemIndex];

    console.log("Item to remove is:", itemToRemove.pId);
    console.log(itemToRemove);
    setIsSaving(true);
    if (itemToRemove && itemToRemove.pId) {
      const removeResponse = await removeMajorPurchases(
        itemToRemove.pId.toString()
      );
    } else {
      // only remove locally so no need to do anything here.
    }

    setIsSaving(false);

    funcitonsformreducer({
      type: "SMP_REMOVE",
      payload: {
        itemIndex: itemIndex,
        data: itemIndex,
      },
    });
    funcitonsformreducer({
      type: "SMP_NUMBER",
      payload: {
        data: datafromreducer.smpNumber - 1,
      },
    });
  };

  const _handleprev = (e) => {
    e.preventDefault();
    pre();
  };

  const inputOnchange = (value, type, itemIndex) => {
    funcitonsformreducer({
      type: "SMP_UPDATE",
      payload: { variable: type, itemIndex: itemIndex, data: value },
    });
  };

  const handleNumPurchasesChange = (value, type, itemIndex) => {
    const numPur = parseInt(value);
    let tempArr = [];
    for (let index = 0; index < numPur; index++) {
      tempArr.push(index + 1);
      // console.log(`tempArr: ${tempArr}`, tempArr);
    }
    const purApp = tempArr;
    console.log(`numPur: ${numPur}`);
    console.log(`purApp: ${purApp}`);
    funcitonsformreducer({
      type: type,
      payload: {
        itemIndex: itemIndex,
        data: numPur,
      },
    });
    // funcitonsformreducer({
    //   type: "SMP_PURCHASES",
    //   payload: purApp,
    // });

    console.log(`datafromreducer.smpNumber: ${datafromreducer.smpNumber}`);
    console.log(
      `datafromreducer.smpPurchases: ${datafromreducer.smpPurchases}`
    );
  };

  const getCurrentVal = (variable, itemIndex) => {
    // console.log(`${CURR_CMP}:: Getting current value for: ${variable}`);
    console.log("getCurrentVal - datafromreducer.smpPurchases");
    console.log(datafromreducer.smpPurchases);
    console.log(`getCurrentVal - variable: ${variable}`);
    console.log(`getCurrentVal - itemIndex: ${itemIndex}`);

    if (itemIndex < 0) {
      return 0;
    }
    switch (variable) {
      // Variables for overall savings for major purchases
      case "SMP_NUMBER":
        return datafromreducer.smpNumber;
      case "SMP_PURCHASES":
        return datafromreducer.smpPurchases;

      // Individual Purchase Item - Identifiers
      case "P_INDEX":
        return datafromreducer.smpPurchases[itemIndex].pIndex;
      case "P_ID":
        return datafromreducer.smpPurchases[itemIndex].pId;

      // Individual Purchase Item - Details
      case "P_NAME":
        return datafromreducer.smpPurchases[itemIndex].pName;
      case "P_YEARS_TO_PURCHASE":
        return datafromreducer.smpPurchases[itemIndex].pYearsToPurchase;
      case "P_AMOUNT_NEEDED":
        return datafromreducer.smpPurchases[itemIndex].pAmountNeeded;
      case "P_AMOUNT_SAVED":
        return datafromreducer.smpPurchases[itemIndex].pAmountSaved;
      case "P_MONTHLY_SAVING":
        return datafromreducer.smpPurchases[itemIndex].pMonthlySaving;
      case "P_ANNUAL_RETURN":
        return datafromreducer.smpPurchases[itemIndex].pAnnualReturn;

      // Individual Purchase Item - Calculated Values
      case "P_MONTHLY_SAVING_REQUIRED":
        return datafromreducer.smpPurchases[itemIndex].pMonthlySavingRequired;
      case "P_ADDITIONAL_SAVING_REQUIRED":
        return datafromreducer.smpPurchases[itemIndex]
          .pAdditionalSavingRequired;
      default:
        return 0;
    }
  };

  const handlePlusClick = (variable, step = 1, itemIndex) => {
    const currVal = getCurrentVal(variable, itemIndex);
    const finalVal = calculateNumValue(true, currVal, step);
    funcitonsformreducer({
      type: "SMP_UPDATE",
      payload: { itemIndex: itemIndex, data: finalVal, variable: variable },
    });
    // if(variable==="SMP_NUMBER") {
    //   handleNumPurchasesChange(finalVal, variable)
    // }
  };

  const handleMinusClick = (variable, step = 1, itemIndex) => {
    const currVal = getCurrentVal(variable, itemIndex);
    const finalVal = calculateNumValue(false, currVal, step);
    funcitonsformreducer({
      type: "SMP_UPDATE",
      payload: { itemIndex: itemIndex, data: finalVal, variable: variable },
    });
    // if(variable==="SMP_NUMBER") {
    //   handleNumPurchasesChange(finalVal, variable)
    // }
  };

  // Function is called everytime increment/decrement button is clicked
  const calculateNumValue = (order, currentVal = 0, step = 1) => {
    const minVal = 0;
    const maxVal = 1000000000000;
    const currVal = parseInt(currentVal);
    if (order) {
      // increment the value by step
      const incrVal = currVal + step;
      if (incrVal > maxVal) {
        return maxVal;
      } else {
        return incrVal;
      }
    } else {
      // decrement the value by step
      const decrVal = currVal - step;
      if (decrVal < minVal) {
        return minVal;
      } else {
        return decrVal;
      }
    }
  };

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Fetch the Initial Data from Backend
    const fetchData = async () => {
      console.log("FETCHING INITIAL Major Purchases DATA!!!");
      // try {
      const response = await getMyMajorPurchases();
      const data = await response.getMyMajorPurchases.data;
      console.log(
        `getMyMajorPurchases Values from backend API Response: ${JSON.stringify(
          data
        )}`
      );
      console.log(data);

      setIsLoaded(true);
      // Assign backend values
      let updateData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        updateData.push({
          pId: data[index].id,
          pName: data[index].name_of_purchase,
          pYearsToPurchase: data[index].num_years_left_for_purchase,
          pAmountNeeded: data[index].amount_needed_as_full_down_payment,
          pAmountSaved: data[index].amount_saved_so_far_for_purchase,
          pMonthlySaving: data[index].current_monthly_savings_for_purchase,
          pAnnualReturn: data[index].exp_avg_annual_return_on_savings,
        });
      }
      console.log(
        `getMyMajorPurchases Values from backend(Parsed): ${JSON.stringify(
          updateData
        )}`
      );
      console.log(updateData);
      console.log(
        `getMyMajorPurchases total purchase items: ${updateData.length}`
      );

      funcitonsformreducer({
        type: "SMP_INITIAL_SYNC",
        payload: { variable: "ALL", data: updateData },
      });

      funcitonsformreducer({
        type: "SMP_NUMBER",
        payload: {
          data: updateData.length,
        },
      });
      if (data.score && data.score.overAllScore) {
        funcitonsformreducer(
          allActions.scoreActions.UpdateScore({
            overAllScore: data.score.overAllScore,
            debtScore: data.score.debtScore,
            riskScore: data.score.riskScore,
            savingsScore: data.score.savingsScore,
            debugInfo: JSON.stringify(data.score),
          })
        );
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <section className="cash_managment">
        <Container maxWidth="lg" className="cash_contain">
          <DetailsFormHeading
            title_text={"Major Purchases"}
            subtitle_text={
              "The accuracy of your score depends on the accuracy of your data."
            }
          />
          {/* The accuracy of your score is determined by the accuracy of your data. */}

          <Box className="cash_management_form">
            <form>
              {/* Savings for major purchases */}
              {/* <Box className="row cash_manag">
                <Box className="cash_managment_sect">
                  <h6>Savings for major purchases</h6>
                </Box>
              </Box> */}

              <Box className="mortage_wrapper web_view">
                <Box className="home_mortage_section">
                  <Box className="info_left_sec">
                    <Typography variant="body1">
                      Number of major purchases
                      <br />
                      (Up to 10)
                    </Typography>
                  </Box>
                  <Box className="right_section_cash">
                    <NumInput
                      value={getCurrentVal("SMP_NUMBER", 0)}
                      onChange={(e) =>
                        handleNumPurchasesChange(e.target.value, "SMP_NUMBER")
                      }
                      onPlusClick={(e) => _handleAddNew(e)}
                      onMinusClick={(e) => _handleRemove(e)}
                      placeholder=""
                      displayCurrency={false}
                    />
                  </Box>
                </Box>
              </Box>

              {datafromreducer.smpPurchases.map((purchaseItem, itemIndex) => (
                <Box key={itemIndex}>
                  <Box className="row cash_manag">
                    <Box className="cash_managment_sect">
                      <h6>
                        Purchase {itemIndex + 1}
                        {/* - ID: {purchaseItem.pId} */}
                      </h6>
                    </Box>
                    <Box className="remove_button">
                      <Button
                        variant="contained"
                        data-item-key={itemIndex}
                        color="error"
                        onClick={(e) => _handleRemove(e, itemIndex)}
                        sx={{ height: "36px" }}
                      >
                        Remove
                      </Button>
                    </Box>
                  </Box>

                  {/* Years Left */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                        <Typography variant="body1">
                          Number of years left till <br />
                          this purchase
                        </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal(
                            "P_YEARS_TO_PURCHASE",
                            itemIndex
                          )}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "P_YEARS_TO_PURCHASE",
                              itemIndex
                            )
                          }
                          onPlusClick={(e) =>
                            handlePlusClick("P_YEARS_TO_PURCHASE", 1, itemIndex)
                          }
                          onMinusClick={(e) =>
                            handleMinusClick(
                              "P_YEARS_TO_PURCHASE",
                              1,
                              itemIndex
                            )
                          }
                          placeholder=""
                          displayCurrency={false}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Amount needed */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                        <Typography variant="body1">
                          Amount needed as a <br />
                          down payment
                        </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("P_AMOUNT_NEEDED", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "P_AMOUNT_NEEDED",
                              itemIndex
                            )
                          }
                          onPlusClick={(e) =>
                            handlePlusClick("P_AMOUNT_NEEDED", 100, itemIndex)
                          }
                          onMinusClick={(e) =>
                            handleMinusClick("P_AMOUNT_NEEDED", 100, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Amount saved */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                        <Typography variant="body1">
                          Amount saved for this <br />
                          purchase
                        </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("P_AMOUNT_SAVED", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "P_AMOUNT_SAVED",
                              itemIndex
                            )
                          }
                          onPlusClick={(e) =>
                            handlePlusClick("P_AMOUNT_SAVED", 100, itemIndex)
                          }
                          onMinusClick={(e) =>
                            handleMinusClick("P_AMOUNT_SAVED", 100, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* Monthly savings */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                        <Typography variant="body1">
                          Current monthly savings for <br />
                          this purchase
                        </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("P_MONTHLY_SAVING", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "P_MONTHLY_SAVING",
                              itemIndex
                            )
                          }
                          onPlusClick={(e) =>
                            handlePlusClick("P_MONTHLY_SAVING", 100, itemIndex)
                          }
                          onMinusClick={(e) =>
                            handleMinusClick("P_MONTHLY_SAVING", 100, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={true}
                        />
                      </Box>
                    </Box>
                  </Box>
                  {/* annual return */}
                  <Box className="mortage_wrapper web_view">
                    <Box className="home_mortage_section">
                      <Box className="info_left_sec">
                        <Typography variant="body1">
                          Expected annual % return <br />
                          on these savings
                        </Typography>
                      </Box>
                      <Box className="right_section_cash">
                        <NumInput
                          value={getCurrentVal("P_ANNUAL_RETURN", itemIndex)}
                          onChange={(e) =>
                            inputOnchange(
                              e.target.value,
                              "P_ANNUAL_RETURN",
                              itemIndex
                            )
                          }
                          onPlusClick={(e) =>
                            handlePlusClick("P_ANNUAL_RETURN", 1, itemIndex)
                          }
                          onMinusClick={(e) =>
                            handleMinusClick("P_ANNUAL_RETURN", 1, itemIndex)
                          }
                          placeholder=""
                          displayCurrency={false}
                        />
                      </Box>
                    </Box>
                    {/* <Box className="mobile_radio_view">
                      <RangeSlider />
                    </Box> */}
                  </Box>
                  {/* Monthly savings required */}
                  <Box
                    className="other_income web_view_mortage"
                    sx={{ display: "none" }}
                  >
                    <Box className="other_income_top">
                      <Box className="other_income_left">
                        <p>Monthly savings required to accomplish this goal:</p>
                      </Box>
                      <p className="total_annual_value">
                        ${getCurrentVal("P_MONTHLY_SAVING_REQUIRED", itemIndex)}{" "}
                        (Todo - calculation remaining)
                      </p>
                    </Box>
                  </Box>
                  {/* additional saving needed */}
                  <Box
                    className="other_income web_view_mortage"
                    sx={{ display: "none" }}
                  >
                    <Box className="other_income_top">
                      <Box className="other_income_left">
                        <p>
                          For this purchase you need an additional saving of
                        </p>
                      </Box>
                      <p className="total_annual_value">
                        $
                        {getCurrentVal(
                          "P_ADDITIONAL_SAVING_REQUIRED",
                          itemIndex
                        )}{" "}
                        (Todo - calculation remaining)
                      </p>
                    </Box>
                  </Box>
                </Box>
              ))}

              {/* Buttons */}
              <br />
              <br />
              <Box className="buttons_section" sx={{ marginTop: "32px" }}>
                <PrevBUtton prev_name="Previous" onClick={_handleprev} />

                <PrevBUtton
                  prev_name="Add Purchase Item"
                  onClick={_handleAddNew}
                />

                <AbsButton title="Next Page" onClick={_handleNextPage} />
              </Box>
            </form>
          </Box>
        </Container>
      </section>
    </>
  );
};

export default MajorPurchases;
