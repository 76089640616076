import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { Container, Box } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";

import "./Footer.css";

import { SiteConstants } from "../../constants/SiteConstants";
import { AuthConstants } from "../../constants/AuthConstants";


import footercircle from "../../assets/images/footer--title--circel4.png";
import footelogo from "../../assets/images/footer-logo.svg";
import facebook from "../../assets/images/social-icons/facebook.png";
import instagram from "../../assets/images/social-icons/instagram.png";
import linkedin from "../../assets/images/social-icons/linkedin.png";
import twitter from "../../assets/images/social-icons/twitter.png";

const Footer2 = () => {
  const validationSchema = Yup.object({
    name: Yup.string("Enter your name")
      .min(1, "Invalid name")
      .matches(AuthConstants.FirstNameRegex, "Please enter valid name")
      .max(40)
      .required("First name is required."),
    email: Yup.string("Enter your email")
      .email("Invalid email address")
      .max(255)
      .required("Email is required."),
    phone: Yup.string("Enter your phone")
      .matches(AuthConstants.PhoneRegex, "Phone number is not valid")
      .min(2, "Invalid phone number field")
      .max(16, "Invalid phone number field"),
    message: Yup.string("Enter your message")
      .min(1, "Invalid message")
      .matches(AuthConstants.LastNameRegex, "Please enter valid message")
      .max(40)
      .required("Message is required."),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      console.log(values);
      // const authResponse = await signup(values['email'], values['password'])
    },
    onValidationError: (errorValues) => {
      console.log("errorValues: Footer Form Validation Errors");
      console.log(errorValues);
      // any action here
      // note that onValidationError is not a built in function
      // but it would be best if it can be achieved this way
      // else any other equivalent solution also can be accepted

    },

  });

  const [footerFrom, setFooterForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handelChangeInput = (event) => {
    setFooterForm({ ...footerFrom, [event.target.name]: event.target.value });
  };
  const hanldeformsubmit = (e) => {
    e.preventDefault();
    console.log(footerFrom);
  };

  return (
    <>
      <footer>

        <Container
          maxWidth="lg"
          className="footer--top"
          sx={{ display: "none" }}
        >
          {/* TITLE Section */}

          <Box className="row">
            <h4 className="title--foot">
              Ready to upgrade your proficiency <br /> in managing your personal
              finances?
              <img
                src={footercircle}
                alt="footercircle"
                className="footercircle"
              />
            </h4>
          </Box>
          <Box className="row col--foot--top">
            <form onSubmit={formik.handleSubmit} className="form--footer">
              <ul>
                <li className="foot---tile--s">
                  {" "}
                  <span className="circle--icons--foot" /> Your Questions /
                  Comments
                </li>
              </ul>

              <input
                type="text"
                name="name"
                value={formik.values.name}
                placeholder="Name"
                onChange={formik.handleChange}
              />
              <input
                type="email"
                name="email"
                value={footerFrom.email}
                placeholder="Email"
                onChange={handelChangeInput}
              />
              <input
                type="number"
                name="phone"
                value={formik.values.phone}
                placeholder="Phone"
                onChange={formik.handleChange}
              />
              <textarea
                value={formik.values.message}
                name="message"
                placeholder="Message"
                cols="30"
                rows="10"
                onChange={formik.handleChange}
              />

              <input type="submit" className="submit--btn" />

              {formik.errors.length > 0 ? (
                <div>
                  {formik.errors.name ? <div>{formik.errors.name}</div> : ""}
                  {formik.errors.email ? <div>{formik.errors.email}</div> : ""}
                  {formik.errors.phone ? <div>{formik.errors.phone}</div> : ""}
                  {formik.errors.message ? (
                    <div>{formik.errors.message}</div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div></div>
              )}
            </form>
          </Box>
        </Container>


        <Container maxWidth="lg" className="footer">
          <Box className="row">
            <Box className="footer--left">
              <img src={footelogo} alt="footer logo" />
            </Box>
            <Box className="footer--right">
              <p>
                <a href="/privacy-policy">Privacy Policy</a> | &nbsp;
                <a href="/about-us">About Us</a> | &nbsp;
                <a href="/faq">FAQs</a> | &nbsp;
                <a href="/disclaimer">Disclaimer</a> | &nbsp;
                <a href="/feedback">Feedback</a> | &nbsp;
                <a href="https://blog.pfscores.com/" target="_blank" rel="noreferrer">Blog</a>
              </p>
            </Box>
            <Box className="footer--right">
              <Box className="social--links">
                <p>
                  <a href={SiteConstants.social_links.facebook} target="_blank">

                    <img
                      className="social--icon"
                      src={facebook}
                      alt="facebook"
                    />
                  </a>
                  <a
                    href={SiteConstants.social_links.instagram}
                    target="_blank"
                  >
                    <img
                      className="social--icon"
                      src={instagram}
                      alt="instagram"
                    />
                  </a>
                  <a href={SiteConstants.social_links.linkedIn} target="_blank">
                    <img
                      className="social--icon"
                      src={linkedin}
                      alt="linkedin"
                    />

                  </a>
                </p>
              </Box>
            </Box>
          </Box>
          {/* <hr></hr> */}
        </Container>

        {/* Container maxWidth="lg" */}
        <Box className="footer--bottom">
          <Box className="footer--copyright">
            <p>
              All rights reserved. {SiteConstants.name.display} ©{" "}
              {new Date().getFullYear()}
            </p>
          </Box>
        </Box>

      </footer>
    </>
  );
};

export default Footer2;
