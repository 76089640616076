import React, { useState, useEffect } from 'react';
import {
  Box,
  Modal,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { 
  completeScore, 
  createNewScore,
   
} from "../../apiServices/pfscoreForms/completeScore";
import { initiatedScoreValues } from "../../apiServices/pfscoreForms/financialHealth";

const SaveScoreModal = ({ open, handleClose, type }) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    description: '',
    isMainScore: false,
  });

  // Fetch initial score values when modal opens for save type
  useEffect(() => {
    if (open && type === 'save') {
      const fetchData = async () => {
        try {
          const response = await initiatedScoreValues();
          if (response.initiatedScoreDetail.code === "SUCCESS") {
            setFormData({
              name: response.initiatedScoreDetail.name || '',
              description: response.initiatedScoreDetail.description || '',
              isMainScore: response.initiatedScoreDetail.isMainScore || false,
            });
          }
        } catch (error) {
          console.error("Error fetching initial values:", error);
        }
      };
      fetchData();
    }
  }, [open, type]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckboxChange = (e) => {
    setFormData(prev => ({
      ...prev,
      isMainScore: e.target.checked
    }));
  };

  const handleSubmit = async () => {
    try {
      if (type === 'new') {
        const response = await createNewScore(
          formData.name,
          formData.description,
          formData.isMainScore
        );
        
        if (response.createNewScore.code === "SUCCESS") {
          console.log("New score created successfully:", response.createNewScore.message);
          navigate("/scoring-details/personal-details");
        } else {
          console.log("Error creating new score:", response.createNewScore.message);
        }
      } else {
        const response = await completeScore(
          formData.name,
          formData.description,
          formData.isMainScore
        );

        if (response.completeMyScore.code === "SUCCESS") {
          console.log("Score saved successfully:", response.completeMyScore.message);
          window.location.reload();
        } else {
          console.log("Error saving score:", response.completeMyScore.message);
        }
      }
      
      // Reset form and close modal
      setFormData({
        name: '',
        description: '',
        isMainScore: false,
      });
      handleClose();
      
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="save-score-modal"
      aria-describedby="save-score-form"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { md: '400px', xs: '250px' },
          background: 'linear-gradient(180deg, #000B40 0%, #00082D 100%)',
          borderRadius: '10px',
          p: 4,
          outline: 'none',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box>
          <Typography
            variant="h6"
            sx={{
              color: '#fff',
              fontSize: '20px',
              fontWeight: 600
            }}
          >
            {type === "new" ? "Create new score" : "Save the Score"}
          </Typography>
          <Typography
          sx={{
            color: '#A0A0A0',
            fontSize: '12px',
          }}
        >
          Fill Out the Details below to {type === "new" ? "Create new score" : "Save the Score"} 
        </Typography>

          </Box>
         
          <CloseIcon
            onClick={handleClose}
            sx={{
              color: '#BFBFBF',
              cursor: 'pointer',
              backgroundColor: '#FFFFFF30',
              borderRadius: '50%',
              padding: '5px',
              '&:hover': { opacity: 0.8 }
            }}
          />
        </Box>
        

        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            fullWidth
            name="name"
            placeholder="Name"
            value={formData.name}
            onChange={handleChange}
            sx={{
              bgcolor: '#000C48',
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                '& fieldset': {
                  borderColor: '#001163',
                },
                '&:hover fieldset': {
                  borderColor: '#001163',
                },
              },
            }}
          />

          <TextField
            fullWidth
            name="description"
            placeholder="Description"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
            sx={{
              bgcolor: '#000C48',
              '& .MuiOutlinedInput-root': {
                color: '#fff',
                '& fieldset': {
                  borderColor: '#001163',
                },
                '&:hover fieldset': {
                  borderColor: '#001163',
                },
              },
            }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formData.isMainScore}
                onChange={handleCheckboxChange}
                sx={{
                  color: '#3479FB',
                  '&.Mui-checked': {
                    color: '#4EEEB3',
                  },
                }}
              />
            }
            label="Is Main Score"
            sx={{ 
              color: 'white',
              '& .MuiFormControlLabel-label': {
                color: 'white'
              }
            }}
          />

          <Button
            onClick={handleSubmit}
            sx={{
              mt: 2,
              background: 'linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%)',
              color: '#000831',
              fontWeight: 600,
              '&:hover': {
                background: 'linear-gradient(267deg, #4EEEB3 -4.01%, #3479FB 89.75%)',
              },
            }}
          >
            {type === "new" ? "Create new score" : "Save"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default SaveScoreModal; 