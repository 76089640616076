import React, { useEffect, useState } from 'react';
import ScoreGraph from '../../../components/scoreGraph/ScoreGraph';
import { getFilteredScores } from '../../../apiServices/pfscoreForms/financialHealth';
import { Typography, CircularProgress, Box } from "@mui/material";
const ScoreGraphPage = () => {
  const [scoreData, setScoreData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTimeOption, setSelectedTimeOption] = useState('All');

  const fetchScoreData = async (timePeriod = selectedTimeOption) => {
    try {
      setIsLoading(true);
      const response = await getFilteredScores(timePeriod);
      if (response.getFilteredScores.code === 'SUCCESS') {
        setScoreData(response.getFilteredScores.data);
      } else {
        console.error('Error:', response.getFilteredScores.message);
      }
    } catch (error) {
      console.error('Failed to fetch score data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTimeFilterChange = (option) => {
    setSelectedTimeOption(option);
    fetchScoreData(option);
  };

  useEffect(() => {
    fetchScoreData();
  }, []);

  if (isLoading) {
    return  <Box 
    display="flex" 
    flexDirection="column" 
    alignItems="center" 
    justifyContent="center" 
    minHeight="200px"
    gap={2}
  >
    <CircularProgress size={40} />
    <Typography variant="h6" color="text.secondary">
      Loading your financial health score...
    </Typography>
  </Box>
  }

  return (
    <ScoreGraph 
      scoreData={scoreData} 
      selectedOption={selectedTimeOption}
      onTimeFilterChange={handleTimeFilterChange}
    />
  );
};

export default ScoreGraphPage; 